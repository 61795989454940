import React from 'react';
import PropTypes from 'prop-types';
import {
  LegendContainer,
  LegendIconCircle,
  LegendLabelTypography,
  RowFlexContainer,
} from '../styledPeers';

export default function CustomLegend({ legend = [] }) {
  return (
    <RowFlexContainer data-testid="smf-bar-legend">
      {legend.map((legendItem) => (
        <LegendContainer key={`${legendItem.label}_${legendItem.colorCode}`}>
          <LegendIconCircle legendColor={legendItem.colorCode} />
          <LegendLabelTypography>{legendItem.label}</LegendLabelTypography>
        </LegendContainer>
      ))}
    </RowFlexContainer>
  );
}

CustomLegend.propTypes = {
  legend: PropTypes.arrayOf({
    label: PropTypes.string,
    colorCode: PropTypes.string,
  }),
};
