import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Skeleton } from '@mui/material';
import { PropTypes } from 'prop-types';
import {
  barInteractionPlugin,
  yLabelPlugin,
  getThemeColors,
  lowerAndReplaceSpace,
} from './utils';
import ThemeWrapper from '../../../utils/ThemeWrapper';
import useWindowSize from '../../../hooks/useWindowSize';
import { getScaledValue } from '../Helper/helper';
import {
  StyledSpcDownloadIconButton,
  StyledSpcChartTitle,
  StyledSpcChartBox,
} from '../../../utils/styles';
import { TEST_IDS } from '../../../constants';

ChartJS.register(CategoryScale, barInteractionPlugin, yLabelPlugin);

export default function HorizontalBar({
  data = [],
  isDark = false,
  chartTitle,
  xAxisTitle,
  selectedIndex = null,
  handleBarSelection = () => null,
  handleSpcResultDownload = () => null,
  isLoading = false,
  isDownloadButtonEnabled = false,
  ypFlag = false,
}) {
  const { width: screenWidth } = useWindowSize();
  const getScaledValueWrapper = (value) => getScaledValue(screenWidth, value);
  const chartRef = useRef();
  const { barBackgroundColor, tickColor, scaleTitleColor, xGridColor } =
    getThemeColors(isDark);
  const chartData = {
    labels: data?.map((d) => d.label),
    datasets: [
      {
        data: data?.map((d) => d.value),
        backgroundColor: [barBackgroundColor],
      },
    ],
  };
  const [chartHeight, setChartHeight] = useState(15);
  const commonDataAndLabelOptions = (showLegend = false) => ({
    interaction: {
      mode: 'x',
    },
    layout: {
      padding: {
        left: getScaledValueWrapper(5),
        top: ypFlag ? '10' : '',
      },
    },
    maintainAspectRatio: false,
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false,
        position: ypFlag && showLegend ? 'bottom' : '',
      },
      tooltip: {
        enabled: false,
      },
      dclCustomLegend:
        ypFlag && showLegend
          ? {
              containerID: 'yp-container',
              ypRca: true,
              labels: {
                generateLabels: () => {
                  return [
                    {
                      text: 'Contribution Percentage',
                    },
                  ];
                },
              },
            }
          : false,
      barInteractionPlugin: {
        isDark,
        selectedIndex,
        handleBarSelection,
        screenWidth,
      },
      yLabelPlugin: {
        isDark,
      },
      datalabels: ypFlag
        ? {
            align: (context) => {
              return context.dataset.data[context.dataIndex] < 0
                ? 'start'
                : 'end';
            },
            anchor: (context) => {
              return context.dataset.data[context.dataIndex] < 0
                ? 'start'
                : 'end';
            },
            formatter: (value) => {
              return `${value}%`;
            },
            color: '#000',
          }
        : null,
    },
  });
  const optionsForDataChart = {
    barPercentage: 0.5,
    scales: {
      x: {
        ...(ypFlag ? { min: -100, max: 100 } : {}),
        display: true,
        beginAtZero: true,
        ticks: {
          display: false,
        },
        border: {
          display: ypFlag,
          dash: ypFlag ? [2, 4] : [],
        },
        grid: {
          color: xGridColor,
          drawBorder: ypFlag,
          tickBorderDash: [5, 5],
          display: true,
          drawTicks: false,
          lineWidth: getScaledValueWrapper(1),
        },
      },
      y: {
        beginAtZero: true,
        border: {
          display: false,
        },
        grid: {
          color: xGridColor,
          tickLength: 230,
          drawOnChartArea: true,
          lineWidth: getScaledValueWrapper(1),
          display: !ypFlag,
        },
        ticks: {
          display: false,
        },
      },
    },
    ...commonDataAndLabelOptions(false),
  };

  const optionsForXLabel = {
    scales: {
      x: {
        ...(ypFlag ? { min: -100, max: 100 } : {}),
        title: {
          display: true,
          text: xAxisTitle,
          font: {
            size: getScaledValueWrapper(12),
          },
          color: scaleTitleColor,
          padding: {
            top: getScaledValueWrapper(40),
          },
        },
        afterFit: (ctx) => {
          ctx.height += getScaledValueWrapper(25);
        },
        beginAtZero: true,
        grid: {
          tickBorderDash: [5, 5],
          display: true,
          drawTicks: false,
        },
        ticks: {
          font: {
            size: getScaledValueWrapper(12),
          },
          color: tickColor,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          tickLength: 230,
          drawOnChartArea: true,
        },
        ticks: {
          display: false,
        },
      },
    },
    ...commonDataAndLabelOptions(true),
  };
  useEffect(() => {
    if (data?.length > 10) {
      setChartHeight(15 + (data.length - 10) * 1.6);
    }
  }, [data]);

  const renderChart = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton
            data-testid="horizontal-bar-chart-title-skeleton"
            sx={{ mb: '0.8rem' }}
            variant="rounded"
            height="2.1rem"
            width="100%"
          />
          <Skeleton variant="rounded" height="17.125rem" width="100%" />
        </>
      );
    }
    return (
      <>
        <StyledSpcChartBox>
          <StyledSpcChartTitle variant="h6" component="h5">
            {chartTitle}
          </StyledSpcChartTitle>
          {isDownloadButtonEnabled && (
            <StyledSpcDownloadIconButton
              disabled={isLoading}
              data
              data-testid={`download-button-${lowerAndReplaceSpace(
                chartTitle
              )}`}
              isLoading={isLoading}
              aria-label="download-file"
              onClick={handleSpcResultDownload}
            >
              <FileDownloadOutlinedIcon className="download-button" />
            </StyledSpcDownloadIconButton>
          )}
        </StyledSpcChartBox>
        <>
          <div
            style={{
              height: '30rem',
              maxHeight: '15rem',
              overflow: 'hidden scroll',
              width: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <div
              data-testid="smf-horizontal-bar-chart"
              style={{
                width: '100%',
                height: `${chartHeight}rem`,
              }}
            >
              <Bar
                ref={chartRef}
                data={chartData}
                options={optionsForDataChart}
                plugins={ypFlag ? [ChartDataLabels] : []}
              />
            </div>
          </div>
          <div
            style={{
              height: '3.125rem',
              display: ypFlag ? 'flex' : '',
              flexDirection: ypFlag ? 'column' : '',
              justifyContent: ypFlag ? 'center' : '',
              alignItems: ypFlag ? 'center' : '',
              marginTop: ypFlag ? '0.10rem' : '',
            }}
          >
            <Bar
              data={{
                labels: [],
                datasets: [
                  {
                    data: data?.map((d) => d.value),
                  },
                ],
              }}
              options={optionsForXLabel}
            />
            {ypFlag && (
              <div
                style={{ marginLeft: '8.5rem' }}
                id="yp-container"
                data-testid={TEST_IDS.SHOW_LEGEND}
              />
            )}
          </div>
        </>
      </>
    );
  };

  return (
    <ThemeWrapper isDark={isDark}>
      {ypFlag ? (
        <div data-testid={TEST_IDS.YP_RCA_CHART}>{renderChart()}</div>
      ) : (
        <Card
          sx={{
            width: '49%',
            padding: '1.5rem',
          }}
        >
          {renderChart()}
        </Card>
      )}
    </ThemeWrapper>
  );
}

HorizontalBar.propTypes = {
  data: PropTypes.instanceOf(Array),
  isDark: PropTypes.bool,
  chartTitle: PropTypes.string,
  xAxisTitle: PropTypes.string,
  selectedIndex: PropTypes.number,
  handleBarSelection: PropTypes.func,
  handleSpcResultDownload: PropTypes.func,
  isLoading: PropTypes.bool,
  isDownloadButtonEnabled: PropTypes.bool,
  ypFlag: PropTypes.bool,
};
