export const formatYAxis = (data, unit) => {
  if (typeof data !== 'number' || !unit) {
    return data.toString();
  }

  const uom = unit.toLowerCase();

  let value = data;
  if ((uom.includes('dollars') || uom.includes('pounds')) && value < 1) {
    value = parseFloat(value.toFixed(1));
  }

  if (value < 1 && !(uom.includes('dollars') || uom.includes('pounds'))) {
    return parseFloat(value.toFixed(1)).toString();
  }

  if (value < 1000) {
    return Math.round(value).toString();
  }

  if (value < 1000000) {
    const formattedValue = (value / 1000).toFixed(1);

    return formattedValue.endsWith('.0')
      ? `${formattedValue.slice(0, -2)}K`
      : `${formattedValue}K`;
  }

  const millions = value / 1000000;
  const formattedValue = millions.toFixed(1);
  const trimmedValue = formattedValue.replace(/\.0$/, '');

  return trimmedValue.endsWith('.00')
    ? `${trimmedValue.slice(0, -3)}M`
    : `${trimmedValue}M`;
};

export const getScaledValue = (screenWidth, value) => {
  if (screenWidth > 3600) {
    return value * 2.625;
  }
  if (screenWidth > 1900) {
    return value * 1.5;
  }
  return value;
};

export const getSmallWidgetY = (screenWidth, theme, chartHeight) => {
  let newTranslateY;
  if (screenWidth >= theme.breakpoints.values.xl)
    newTranslateY = chartHeight + 30;
  else if (screenWidth >= theme.breakpoints.values.lg)
    newTranslateY = chartHeight + 5;
  else newTranslateY = chartHeight / 2 + 48;

  return newTranslateY;
};

export const remToPixels = (remValue) => {
  const rootFontSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize
  );
  return remValue * rootFontSize;
};
