import { useState, useEffect } from 'react';

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    height: undefined,
    width: undefined,
  });

  useEffect(() => {
    function resizeTracker() {
      setWindowSize({ height: window.innerHeight, width: window.innerWidth });
    }

    window.addEventListener('resize', resizeTracker);

    resizeTracker();

    return () => window.removeEventListener('resize', resizeTracker);
  }, []);

  return windowSize;
}
