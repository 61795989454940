import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import ThemeWrapper from '../../../utils/ThemeWrapper';
import {
  AbsoluteBox,
  DonutWithMetricBox,
  OEEMetricShorthand,
  OEETargets,
  RelativeBox,
  StyledCircularProgress,
} from '../styledPeers';
import {
  fillPercent,
  getDonutGaugeSizes,
  getDonutRadius,
  getValueWithSymbol,
} from './helperFunctions';
import { getGridFrameWidth, getStyleProps } from '../Gauge/helperFunctions';
import useWindowSize from '../../../hooks/useWindowSize';
import Tooltip1D from '../Gauge/tooltip1d';

function Donut({ isFirst, data, isExpanded, symbol, donutSize }) {
  if (!donutSize) return null;

  const donutRadius = `${Math.ceil(
    donutSize.height * getDonutRadius(isExpanded, isFirst)
  )}px`;
  const donutThickness = getDonutGaugeSizes(
    isExpanded,
    isFirst,
    [4.8, 4.7, 4.3, 4.5]
  );
  const percentTextSize = `${Math.ceil(donutSize.width / 4)}px`;

  const { value, colorCode, target, isReset, displayValue } = data;
  const fillValue = fillPercent(value, target, isReset);

  return (
    <RelativeBox data-testid="smf-donut-container">
      <RelativeBox>
        <StyledCircularProgress
          value={Math.min(Math.max(fillValue, 0), 100)}
          colorCode={colorCode}
          size={donutRadius}
          thickness={donutThickness}
        />
        <AbsoluteBox>
          <StyledCircularProgress
            isBG
            value={100}
            size={donutRadius}
            thickness={donutThickness}
          />
        </AbsoluteBox>
      </RelativeBox>
      <AbsoluteBox>
        <Typography
          variant="caption"
          component="div"
          sx={{
            color: (theme) => theme.palette.text.primary,
            fontSize: percentTextSize,
            fontWeight: 400,
          }}
          data-testid="smf-donut-actual-value"
        >
          {getValueWithSymbol(value, symbol, displayValue, isReset, true)}
        </Typography>
      </AbsoluteBox>
    </RelativeBox>
  );
}

export default function DonutChart({
  isExpanded,
  isShowTypeLabelFull,
  isDark = false,
  data,
  donutsMeta = [
    { fullLabel: 'OEE', shortLabel: 'OEE', key: 'oee' },
    { fullLabel: 'Availability', shortLabel: 'A', key: 'availability' },
    { fullLabel: 'Performance', shortLabel: 'P', key: 'performance' },
    { fullLabel: 'Quality', shortLabel: 'Q', key: 'quality' },
  ],
  isShowTargets = true,
  isShowBigChartLabel = false,
  symbol = { value: '%', position: 'suffix' },
  isShowTooltip,
}) {
  const bigDonut = donutsMeta[0];
  const smallDonuts = donutsMeta.slice(1);

  const { width } = useWindowSize();
  const containerRef = React.useRef(null);
  const bigDonutGridRef = React.useRef(null);
  const smallDonutGridRef = React.useRef(null);

  const bigDonutSize = getStyleProps(
    isExpanded,
    width,
    containerRef,
    bigDonutGridRef,
    smallDonutGridRef,
    null,
    {},
    'big'
  );

  const smallDonutSize = getStyleProps(
    isExpanded,
    width,
    containerRef,
    bigDonutGridRef,
    smallDonutGridRef
  );

  const oeeMetricProps = {
    fontSize: Math.min(smallDonutSize?.targetFontSize ?? '1rem', '1.994375rem'),
    ...(isExpanded && { lineHeight: '3rem', marginBottom: '1rem' }),
  };

  return (
    <ThemeWrapper isDark={isDark}>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
        ref={containerRef}
        data-testid="smf-donuts-wrapper"
      >
        <Grid
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'flex-end',
            flexWrap: 'nowrap',
            height: 'fit-content',
            columnGap: isExpanded ? '0rem' : '.5rem',
            maxWidth: `${getGridFrameWidth(
              isExpanded,
              containerRef.current?.offsetWidth ?? '400'
            )}%`,
            margin: 'auto',
          }}
        >
          <Grid item xs={4} ref={bigDonutGridRef}>
            <DonutWithMetricBox>
              <Tooltip1D
                isShowTooltip={isShowTooltip}
                label={bigDonut.fullLabel}
                actualValue={getValueWithSymbol(
                  data[bigDonut.key].value,
                  symbol,
                  data[bigDonut.key].displayValue,
                  data[bigDonut.key].isReset
                )}
                targetValue={getValueWithSymbol(
                  data[bigDonut.key].target,
                  symbol,
                  data[bigDonut.key].displayTarget
                )}
              >
                {isShowBigChartLabel && (
                  <OEEMetricShorthand
                    data-testid="smf-donut-type-label"
                    {...oeeMetricProps}
                  >
                    {isShowTypeLabelFull
                      ? bigDonut.fullLabel
                      : bigDonut.shortLabel}
                  </OEEMetricShorthand>
                )}
                <Donut
                  isFirst
                  data={data[bigDonut.key]}
                  isExpanded={isExpanded}
                  symbol={symbol}
                  donutSize={bigDonutSize}
                />
                {isShowTargets && (
                  <OEETargets
                    isExpand={isExpanded}
                    data-testid="smf-donut-target-value"
                    targetFontSize={bigDonutSize?.targetFontSize}
                  >
                    {`(${getValueWithSymbol(
                      data[bigDonut.key].target,
                      symbol,
                      data[bigDonut.key].displayTarget
                    )})`}
                  </OEETargets>
                )}
              </Tooltip1D>
            </DonutWithMetricBox>
          </Grid>
          {smallDonuts.map((smallDonut, idx) => (
            <Grid
              item
              xs={2.6}
              key={`${smallDonut.key}_${idx + 1}`}
              {...(!idx && { ref: smallDonutGridRef })}
            >
              <DonutWithMetricBox>
                <Tooltip1D
                  isShowTooltip={isShowTooltip}
                  label={smallDonut.fullLabel}
                  actualValue={getValueWithSymbol(
                    data[smallDonut.key].value,
                    symbol,
                    data[smallDonut.key].displayValue,
                    data[smallDonut.key].isReset
                  )}
                  targetValue={getValueWithSymbol(
                    data[smallDonut.key].target,
                    symbol,
                    data[smallDonut.key].displayTarget
                  )}
                >
                  <OEEMetricShorthand
                    data-testid="smf-donut-type-label"
                    {...oeeMetricProps}
                  >
                    {isShowTypeLabelFull
                      ? smallDonut.fullLabel
                      : smallDonut.shortLabel}
                  </OEEMetricShorthand>
                  <Donut
                    data={data[smallDonut.key]}
                    isExpanded={isExpanded}
                    symbol={symbol}
                    donutSize={smallDonutSize}
                  />
                  {isShowTargets && (
                    <OEETargets
                      isExpand={isExpanded}
                      data-testid="smf-donut-target-value"
                      targetFontSize={smallDonutSize?.targetFontSize}
                      {...(isExpanded && { ml: 2 })}
                    >
                      {`(${getValueWithSymbol(
                        data[smallDonut.key].target,
                        symbol,
                        data[smallDonut.key].displayTarget
                      )})`}
                    </OEETargets>
                  )}
                </Tooltip1D>
              </DonutWithMetricBox>
            </Grid>
          ))}
        </Grid>
      </div>
    </ThemeWrapper>
  );
}

export const donutPropShape = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  target: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colorCode: PropTypes.string,
  isReset: PropTypes.bool,
  displayValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const donutChartPropShape = {
  isExpanded: PropTypes.bool,
  isShowTypeLabelFull: PropTypes.bool,
  isDark: PropTypes.bool,
  data: PropTypes.shape({
    oee: donutPropShape,
    availability: donutPropShape,
    performance: donutPropShape,
    quality: donutPropShape,
  }),
};

Donut.propTypes = {
  isFirst: PropTypes.bool,
  data: donutPropShape,
  isExpanded: PropTypes.bool,
  symbol: PropTypes.shape({
    value: PropTypes.string,
    position: PropTypes.oneOf(['prefix', 'suffix']),
  }),
  donutSize: PropTypes.instanceOf(Object),
};

DonutChart.propTypes = {
  isExpanded: PropTypes.bool,
  isShowTypeLabelFull: PropTypes.bool,
  isDark: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  donutsMeta: PropTypes.instanceOf(Array),
  isShowTargets: PropTypes.bool,
  isShowBigChartLabel: PropTypes.bool,
  symbol: PropTypes.shape({
    value: PropTypes.string,
    position: PropTypes.oneOf(['prefix', 'suffix']),
  }),
  isShowTooltip: PropTypes.bool,
};
