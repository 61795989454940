import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import InfoIcon from '../../assets/svgs/infoIcon.svg';
import ToggleSwitch from './ToggleSwitch';
import SmfTooltip from '../SmfTooltip';
import ThemeWrapper from '../../utils/ThemeWrapper';
import MESSAGE_STRINGS from '../../constants/en-us';
import {
  ToggleTypography,
  BoldTypography,
  StyledBox,
} from '../../utils/styles';

function SmfToggle({
  isSwitched,
  setIsSwitched,
  toggleTitle = MESSAGE_STRINGS['Toggle.default.title'],
  toggleLeftOption = MESSAGE_STRINGS['Toggle.default.left.option'],
  toggleRightOption = MESSAGE_STRINGS['Toggle.default.right.option'],
  isDark = false,
  ...rest
}) {
  const tooltipText = MESSAGE_STRINGS['Toggle.default.tooltip.text'];
  const boldTextPlanned =
    MESSAGE_STRINGS['Toggle.default.tooltip.boldTextPlanned'];
  const plannedText = MESSAGE_STRINGS['Toggle.default.tooltip.plannedText'];
  const boldTextMax = MESSAGE_STRINGS['Toggle.default.tooltip.boldTextMax'];
  const maxText = MESSAGE_STRINGS['Toggle.default.tooltip.maxText'];

  return (
    <ThemeWrapper isDark={isDark}>
      <StyledBox>
        <Box>
          <SmfTooltip
            placement="top"
            disableFocusListener
            disableTouchListener
            title={
              <span style={{ fontSize: '0.625rem' }}>
                {tooltipText} <BoldTypography>{boldTextPlanned}</BoldTypography>{' '}
                {plannedText} <BoldTypography>{boldTextMax}</BoldTypography>
                {maxText}
              </span>
            }
          >
            <Box sx={{ height: '1rem', width: '1rem' }}>
              <InfoIcon />
            </Box>
          </SmfTooltip>
        </Box>

        <ToggleTypography>
          <ToggleTypography component="span" textColor="secondary">
            {toggleTitle}
          </ToggleTypography>
          <ToggleTypography component="span">
            {toggleLeftOption}
          </ToggleTypography>
        </ToggleTypography>
        <ToggleSwitch
          isSwitched={isSwitched}
          setIsSwitched={setIsSwitched}
          {...rest}
        />
        <ToggleTypography>{toggleRightOption}</ToggleTypography>
      </StyledBox>
    </ThemeWrapper>
  );
}

export default SmfToggle;

SmfToggle.propTypes = {
  toggleTitle: PropTypes.string,
  toggleLeftOption: PropTypes.string,
  toggleRightOption: PropTypes.string,
  isSwitched: PropTypes.bool,
  setIsSwitched: PropTypes.func,
  isDark: PropTypes.bool,
};
