import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import SmfSingleHorizontalBarChart from '../../SmfSingleHorizontalBar';
import ThemeWrapper from '../../../utils/ThemeWrapper';
import {
  MetricNameTypo,
  MetricValueTypo,
  MetricTargetTypo,
  MetricsItemGrid,
  VerticalBar,
} from './style';

function isNullOrUndefined(variable) {
  return typeof variable === 'undefined' || variable === null;
}

function DisplayValue({ value }) {
  const actual = typeof value.actual === 'undefined' ? value : value.actual;
  const isNA =
    isNullOrUndefined(actual) ||
    `${actual}` === '-1' ||
    `${value?.target}` === '-1';
  const showTarget = !isNA && !isNullOrUndefined(value.target);
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {isNA ? (
        <MetricTargetTypo data-testid="smf-process-flow-metrics-NA">
          N/A
        </MetricTargetTypo>
      ) : (
        <>
          <MetricValueTypo
            title={actual}
            data-testid="smf-process-flow-metrics-value"
            textColor={value.textColor}
          >
            {actual}
          </MetricValueTypo>
          {showTarget && (
            <>
              <VerticalBar />
              <MetricTargetTypo
                title={value.target}
                data-testid="smf-process-flow-metrics-target"
              >
                {value.target}
              </MetricTargetTypo>
            </>
          )}
        </>
      )}
    </div>
  );
}
DisplayValue.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default function ProcessFlow({
  isDark,
  metrics = [{ name: 'Throughput', value: 0 }],
  target,
  targetUnits = 0,
  markObj = {},
  caption = 'of target for current shift',
  getPercentageColor,
  colorMapping = {
    calculatedTarget: {
      getColor: (theme) => theme.palette.secondary.dark,
      label: 'Dynamic Target',
    },
  },
}) {
  const getTargetUnits = () =>
    `${targetUnits}` === '-1' ? 'N/A' : targetUnits;
  const getTarget = () => {
    if (target === undefined) return '-';
    if (`${target}` === '-1') return 'N/A';
    return target;
  };

  return (
    <ThemeWrapper isDark={isDark}>
      <div
        style={{ height: '100%', width: '100%' }}
        data-testid="smf-process-flow-wrapper"
      >
        <Grid
          container
          justifyContent="center"
          data-testid="smf-process-flow-metrics-box"
        >
          {metrics.map(({ name, value }, idx) => (
            <MetricsItemGrid key={`${name}_${idx + 1}`}>
              <MetricNameTypo
                title={name}
                data-testid="smf-process-flow-metrics-name"
              >
                {name}
              </MetricNameTypo>
              <DisplayValue value={value} />
            </MetricsItemGrid>
          ))}
        </Grid>
        <SmfSingleHorizontalBarChart
          target={getTarget()}
          targetUnits={getTargetUnits()}
          caption={caption}
          markObj={markObj}
          getPercentageColor={getPercentageColor}
          isDark={isDark}
          colorMapping={colorMapping}
        />
      </div>
    </ThemeWrapper>
  );
}

ProcessFlow.propTypes = {
  isDark: PropTypes.bool,
  metrics: PropTypes.instanceOf(Array),
  target: PropTypes.number,
  targetUnits: PropTypes.number,
  caption: PropTypes.string,
  markObj: PropTypes.instanceOf(Object),
  getPercentageColor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  colorMapping: PropTypes.instanceOf(Object),
};
