import React from 'react';
import { Line } from 'react-chartjs-2';
import { PropTypes } from 'prop-types';
import { CategoryScale, Chart as ChartJS } from 'chart.js/auto';
import { Skeleton } from '@mui/material';
import { getThemeColors } from '../HorizontalBar/utils';
import { createDataset, dclCustomLegend } from './utils';
import useWindowSize from '../../../hooks/useWindowSize';
import { getScaledValue } from '../Helper/helper';
import { tooltipCustom } from '../../SmfHorizontalBoxChart/utils';

ChartJS.register(CategoryScale, dclCustomLegend);

export default function DynamicControlChart({
  data,
  theme,
  yLabelTitle,
  xLabelTitle,
  showLoader = false,
  isDark = false,
  enableXGrid = false,
  enableTooltip = false,
  ypFlag = false,
}) {
  const { width: screenWidth } = useWindowSize();
  const getScaledValueWrapper = (value) => {
    const scaledValue = getScaledValue(screenWidth, value);
    return scaledValue;
  };
  const { backgroundColorForTooltip, fontColor } = tooltipCustom(isDark);
  const { tickColor, scaleTitleColor, xGridColor } = getThemeColors(isDark);
  const { datasets, labels, xTicksCount } = createDataset(
    data,
    theme,
    getScaledValueWrapper
  );
  const options = {
    hover: { mode: null },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'x',
      intersect: false,
    },
    plugins: {
      yLabelPlugin: false,
      barInteractionPlugin: false,
      dclCustomLegend: {
        containerID: `dcl-legend-container${enableTooltip ? '-expanded' : ''}`,
      },
      legend: {
        display: false,
        reverse: true,
        position: 'bottom',
        fullSize: false,
        labels: {
          color: tickColor,
          usePointStyle: true,
          pointStyle: 'line',
          boxHeight: getScaledValueWrapper(8),
          boxWidth: getScaledValueWrapper(20),
          font: {
            size: getScaledValueWrapper(12),
          },
        },
      },
      tooltip: {
        intersect: false,
        padding: getScaledValueWrapper(6),
        backgroundColor: backgroundColorForTooltip,
        titleColor: fontColor,
        bodyColor: fontColor,
        titleFont: {
          family: 'Open Sans',
          weight: 600,
          size: getScaledValueWrapper(12),
          style: 'normal',
        },
        bodyFont: {
          family: 'Open Sans',
          weight: 400,
          size: getScaledValueWrapper(11),
          style: 'normal',
        },
        enabled: enableTooltip,
        position: 'nearest',
        itemSort: (a, b) => a.dataset?.label.localeCompare(b.dataset?.label),
      },
    },
    scales: {
      x: {
        ...(ypFlag ? { type: 'linear' } : {}),
        title: {
          font: {
            size: getScaledValueWrapper(12),
          },
          display: true,
          text: xLabelTitle,
          color: scaleTitleColor,
        },
        beginAtZero: true,
        grid: {
          display: enableXGrid,
          drawBorder: enableXGrid,
          color: xGridColor,
          lineWidth: getScaledValueWrapper(1),
        },
        border: {
          width: getScaledValueWrapper(1),
          display: enableXGrid,
          dash: [2, 3],
        },
        ticks: {
          font: {
            size: getScaledValueWrapper(12),
          },
          color: tickColor,
          maxTicksLimit: xTicksCount,
        },
      },
      y: {
        title: {
          font: {
            size: getScaledValueWrapper(12),
          },
          display: true,
          text: yLabelTitle,
          color: scaleTitleColor,
        },
        grid: {
          color: xGridColor,
          drawBorder: false,
          lineWidth: getScaledValueWrapper(1),
        },
        border: { display: false },
        ticks: {
          font: {
            size: getScaledValueWrapper(12),
          },
          callback: function callback(value) {
            if (ypFlag) {
              return `${value}%`;
            }
            return value;
          },
          count: 6,
          color: tickColor,
        },
      },
    },
  };

  const d = {
    labels,
    datasets,
  };
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '0.8125rem',
      }}
    >
      {showLoader ? (
        <Skeleton
          data-testid="dcl-chart-loader"
          variant="rectangular"
          width="100%"
          height="100%"
        />
      ) : (
        <>
          <Line
            data-testid="dcl-chart"
            id="dcl-chart"
            options={options}
            data={d}
          />
          <div
            id={`dcl-legend-container${enableTooltip ? '-expanded' : ''}`}
            data-testid={`dcl-legend-container${
              enableTooltip ? '-expanded' : ''
            }`}
          />
        </>
      )}
    </div>
  );
}
DynamicControlChart.propTypes = {
  data: PropTypes.instanceOf(Array),
  theme: PropTypes.instanceOf(Object),
  yLabelTitle: PropTypes.string,
  xLabelTitle: PropTypes.string,
  isDark: PropTypes.bool,
  enableXGrid: PropTypes.bool,
  enableTooltip: PropTypes.bool,
  showLoader: PropTypes.bool,
  ypFlag: PropTypes.bool,
};
