import { Box, Typography, styled } from '@mui/material';

const MainContainer = styled(Box, {
  shouldForwardProp: (p) => p !== 'statusKey',
})(({ theme, statusKey }) => {
  /**
   * Default styles
   */
  const styles = {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.charts.unavailable,
    borderRadius: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.1875rem .5rem',
    width: 'fit-content',
    /**
     * maxWidth added below in mind of widget, shrink or stretch as needed by the status / usecase.
     *? Needed to bring up the ellipsis
     */
    maxWidth: '6rem',
  };
  /**
   * if new status introduced, we can add custom styles in below swich case.
   */
  (() => {
    switch (statusKey) {
      case 'RUN': {
        styles.backgroundColor = theme.palette.threshold.green;
        break;
      }
      case 'STOP': {
        styles.backgroundColor = theme.palette.threshold.red;
        break;
      }
      case 'N/A': {
        styles.backgroundColor = theme.palette.grey.grey400;
        break;
      }
      default: {
        break;
      }
    }
  })();
  return styles;
});

const StatusTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrast,
  fontWeight: 400,
  fontSize: '.75rem',
  lineHeight: '1.245rem',
}));

export { MainContainer, StatusTypo };
