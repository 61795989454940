import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import ThemeWrapper from '../../utils/ThemeWrapper';
import Breadcrumbs from './breadcrumbs';

function SmfBreadcrumbs({ isDark, ...OtherProps }) {
  return (
    <ThemeWrapper isDark={isDark}>
      <BrowserRouter>
        <Breadcrumbs {...OtherProps} />
      </BrowserRouter>
    </ThemeWrapper>
  );
}

SmfBreadcrumbs.propTypes = {
  isDark: PropTypes.bool,
  linksList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  callback: PropTypes.func,
};

export default SmfBreadcrumbs;
