/**
 * @param {boolean} isExpanded
 * @param {boolean} isFirst
 * @param {array} sizes
 * @returns {array} string from the sizes array
 *
 * based on the requirement returns the size
 */
function getDonutGaugeSizes(isExpanded, isFirst, sizes) {
  if (isExpanded && isFirst) {
    return sizes[0];
  }
  if (isExpanded && !isFirst) {
    return sizes[1];
  }
  if (!isExpanded && isFirst) {
    return sizes[2];
  }
  return sizes[3];
}

/**
 * @param {boolean} isExpanded
 * @param {boolean} isFirst
 * @returns {number} radius percent of the donut
 */
function getDonutRadius(isExpanded, isFirst) {
  if (isExpanded) return 0.7;
  return isFirst ? 0.85 : 0.75;
}

/**
 * @param {number} filled
 * @param {number} total
 * @returns {number} percentage to fill color
 */
function fillPercent(filled, total, isReset) {
  if (isReset || Number.isNaN(filled) || Number.isNaN(total)) return 0;
  if (total === 0) return 0;
  const result = Math.round((filled / total) * 100);
  return Number.isNaN(result) ? 0 : result;
}

/**
 * @param {number | string} value
 * @param {object} symbol
 * @param {number | string} displayValue
 * @param {boolean} isReset
 * @returns {string} value to render in chart
 */
function getValueWithSymbol(value, symbol, displayValue, isReset, isTruncate) {
  if (isReset) return '-';
  if (displayValue)
    return Number.isInteger(Number(displayValue)) || !isTruncate
      ? displayValue
      : Number(displayValue).toFixed(1);
  const { value: symbolChar = '', position } = symbol;
  return position === 'prefix'
    ? `${symbolChar}${value}`
    : `${value}${symbolChar}`;
}

export { getDonutGaugeSizes, getDonutRadius, fillPercent, getValueWithSymbol };
