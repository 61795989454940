import React from 'react';

import moment from 'moment-timezone';
import { PropTypes } from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ThemeWrapper from '../../utils/ThemeWrapper';
import { StyledDateCalendar, ErrorMessage, StyledSpan } from './style';
import useWindowSize from '../../hooks/useWindowSize';

const fourkResolution = {
  layout: {
    sx: {
      '.MuiDayCalendar-root > .MuiDayCalendar-header': {
        justifyContent: 'space-evenly',
        margin: '1rem 0',
        '.MuiDayCalendar-weekDayLabel': {
          padding: '0 1rem',
        },
      },
      '.MuiDateCalendar-root': {
        width: '16.5rem',
        height: '20rem',
        maxHeight: '21rem',
        padding: '1rem',
        '.MuiPickersCalendarHeader-root': {
          margin: '1.2rem 0',
        },
        '.MuiPickersFadeTransitionGroup-root': {
          div: {
            '.MuiYearCalendar-root': {
              width: '100%',
              height: '16rem',
              maxHeight: '17rem',
              '.MuiPickersYear-root': {
                '.MuiPickersYear-yearButton': {
                  height: '2rem',
                  width: '3rem',
                  borderRadius: '2rem',
                },
              },
            },
          },
        },
      },

      '.MuiDayCalendar-root > .MuiPickersSlideTransition-root': {
        height: '15rem',
      },
      '.MuiDayCalendar-root > .MuiPickersSlideTransition-root > .MuiDayCalendar-monthContainer > .MuiDayCalendar-weekContainer':
        {
          justifyContent: 'space-evenly',
          '.MuiPickersDay-root': {
            padding: '1rem',
          },
        },
    },
  },
};
const twoKResolution = {
  layout: {
    sx: {
      '.MuiDayCalendar-root > .MuiDayCalendar-header': {
        justifyContent: 'space-evenly',
        margin: '1rem 0',
        '.MuiDayCalendar-weekDayLabel': {
          padding: '0.5rem',
        },
      },
      '.MuiDateCalendar-root': {
        width: '15.5rem',
        height: '17rem',
        maxHeight: '17rem',
        padding: '0.5rem',
        '.MuiPickersCalendarHeader-root': {
          margin: '0.5rem 0',
          paddingLeft: '0.89rem',
          '.MuiPickersCalendarHeader-labelContainer': {
            '.MuiPickersFadeTransitionGroup-root': {
              '.MuiPickersCalendarHeader-label': {
                fontSize: '0.89rem',
                height: 'max-content',
              },
            },
          },
        },
        '.MuiPickersFadeTransitionGroup-root': {
          div: {
            '.MuiYearCalendar-root': {
              width: '100%',
              height: '14.5rem',
              maxHeight: '15rem',
              padding: '0.7rem',
              '.MuiPickersYear-root': {
                '.MuiPickersYear-yearButton': {
                  height: '2rem',
                  fontSize: '1rem',
                  width: '3rem',
                  borderRadius: '1rem',
                },
              },
            },
          },
        },
      },

      '.MuiDayCalendar-root > .MuiPickersSlideTransition-root': {
        height: '15rem',
      },
      '.MuiDayCalendar-root > .MuiPickersSlideTransition-root > .MuiDayCalendar-monthContainer > .MuiDayCalendar-weekContainer':
        {
          justifyContent: 'space-evenly',
          '.MuiPickersDay-root': {
            padding: '0.5rem',
          },
        },
    },
  },
};

const generateSlotProps = (screenWidth) => {
  let resoulution = {
    '.MuiDateCalendar-root': {
      '.MuiPickersCalendarHeader-root': {
        tabIndex: 0,
        zIndex: 10,
      },
    },
  };
  if (screenWidth > 3500) {
    resoulution = fourkResolution;
  } else if (screenWidth > 1900) {
    resoulution = twoKResolution;
  }
  return resoulution;
};

export default function SmfDateCalendar({
  value = null,
  isDark = false,
  onChange = () => {},
  placeholderText = '',
  timezone = 'America/Chicago',
  disabled = false,
  readOnly = false,
  shouldDisableDate = () => {},
  isError = false,
  errorHelperText = '',
}) {
  const { width: screenWidth } = useWindowSize();

  moment.tz.setDefault(timezone);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeWrapper isDark={isDark}>
        <StyledDateCalendar
          data-testid="smf-dateCalendar"
          className="smf-dateCalendar"
          aria-atomic="true"
          label={placeholderText}
          value={moment(value)}
          onChange={onChange}
          shouldDisableDate={shouldDisableDate}
          disabled={disabled}
          readOnly={readOnly}
          error={isError}
          slotProps={{
            ...generateSlotProps(screenWidth),
            textField: {
              InputLabelProps: {
                shrink: true,
                sx: { '&.Mui-focused': (theme) => theme.palette.error.main },
              },
            },
          }}
        />
        {isError && (
          <ErrorMessage
            screenWidth={screenWidth}
            aria-label={errorHelperText}
            tabIndex={0}
          >
            <StyledSpan>{errorHelperText}</StyledSpan>
          </ErrorMessage>
        )}
      </ThemeWrapper>
    </LocalizationProvider>
  );
}

SmfDateCalendar.propTypes = {
  onChange: PropTypes.func,
  isDark: PropTypes.bool,
  value: PropTypes.string,
  placeholderText: PropTypes.string,
  shouldDisableDate: PropTypes.func,
  timezone: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  isError: PropTypes.bool,
  errorHelperText: PropTypes.string,
};
