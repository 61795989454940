import React from 'react';
import { Box, CircularProgress, Grid, styled, Typography } from '@mui/material';
import { typographyStyles } from '../../utils/styles';

const AbsoluteBox = styled(Box)(() => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const RelativeBox = styled(Box)(() => ({
  position: 'relative',
  display: 'inline-flex',
  height: 'fit-content',
}));

const DonutWithMetricBox = styled(Box)(() => ({
  textAlign: 'center',
}));

const OEEMetricShorthand = styled(Typography, {
  shouldForwardProp: (p) =>
    !['fontSize', 'lineHeight', 'marginBottom'].includes(p),
})(
  ({
    theme,
    fontSize = '1rem',
    lineHeight = '1.75rem',
    marginBottom = '.5rem',
    isOverflow,
    isOverflowActual,
  }) => ({
    color: theme.palette.text.secondary,
    fontSize,
    fontWeight: 400,
    lineHeight,
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: (isOverflow || isOverflowActual) && 'default',
    textOverflow: 'ellipsis',
    [theme.breakpoints.up('lg')]: {
      overflow: 'hidden',
    },
    marginBottom,
  })
);

const OEETargets = styled(Typography, {
  shouldForwardProp: (p) => !['isExpand', 'targetFontSize'].includes(p),
})(({ theme, isExpand, targetFontSize }) => ({
  color: theme.palette.text.secondary,
  fontSize: isExpand ? '1.5rem' : targetFontSize ?? '.75rem',
  fontWeight: 400,
  lineHeight: isExpand ? '3.5rem' : '1.245rem',
  marginLeft: isExpand ? '1rem' : '.5rem',
  textAlign: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

const StyledCircularProgress = styled(
  (props) => <CircularProgress variant="determinate" {...props} />,
  {
    shouldForwardProp: (p) => !['isBG', 'colorCode'].includes(p),
  }
)(({ theme, isBG, colorCode }) => {
  const progressColor = isBG ? theme.palette.grey.grey400 : colorCode;
  return {
    color: progressColor,
    zIndex: isBG ? 1 : 2,
    ...(!isBG && {
      '.MuiCircularProgress-circle': {
        strokeLinecap: 'round',
      },
    }),
  };
});

const GaugeGridItem = styled(
  React.forwardRef((props, ref) => <Grid item {...props} ref={ref} />)
)(() => ({
  textAlign: 'center',
}));

const LineTooltipBox = styled(Box)(({ theme }) => ({
  background: theme.palette.grey.grey600,
  color: theme.palette.primary.contrast,
  borderRadius: '.25rem',
  padding: '.25rem .8rem',
}));

const LineTooltipTypo = styled(Typography)(() => ({
  fontSize: '.625rem',
  lineHeight: '.875rem',
  span: {
    fontWeight: 'bold',
  },
}));

const RowFlexContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const LegendContainer = styled(Box)(() => ({
  marginRight: '0.75rem',
  display: 'flex',
  alignItems: 'center',
}));

const LegendIconCircle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'legendColor',
})(({ legendColor }) => ({
  height: '0.75rem',
  width: '0.75rem',
  borderRadius: '50%',
  backgroundColor: legendColor,
  marginRight: '0.25rem',
}));

const LegendLabelTypography = styled(Typography)(({ theme }) => ({
  ...typographyStyles,
  fontSize: '0.625rem',
  lineHeight: '0.875rem',
  color: theme.palette.grey.grey800,
}));

const LimitsAbsoluteBox = styled(Box, {
  shouldForwardProp: (p) => !['isExpanded', 'isRight', 'value'].includes(p),
})(({ isExpanded, isRight, value }) => {
  let numberOfDigits = 1;
  if (value) {
    numberOfDigits = value.toString().length;
  }
  let right = '-14%';
  if (numberOfDigits >= 2) {
    const factor = -13 + -((numberOfDigits - 1) * 5.8);
    right = `${factor}%`;
  }
  const getPosition = () => {
    return isRight ? { right } : { left: '-14%' };
  };

  return {
    bottom: isExpanded ? '26%' : '23%',
    position: 'absolute',
    ...getPosition(),
  };
});

const LimitsTypography = styled(Typography, {
  shouldForwardProp: (p) => !['textColor', 'isExpanded'].includes(p),
})(({ textColor, isExpanded }) => {
  return {
    ...typographyStyles,
    fontWeight: 400,
    color: textColor,
    fontSize: isExpanded ? '1.4rem' : '1rem',
  };
});

const SingleGaugeTiltleTypography = styled(Typography, {
  shouldForwardProp: (p) =>
    !['textColor', 'isExpanded', 'marginTop'].includes(p),
})(({ textColor, isExpanded, marginTop }) => {
  return {
    ...typographyStyles,
    fontWeight: 400,
    color: textColor,
    fontSize: isExpanded ? '1.4rem' : '1rem',
    marginTop,
  };
});

const Tooltip1DBox = styled(Box)(() => ({ padding: '.25rem' }));

const Tooltip1DLabel = styled(Typography)(() => ({
  fontSize: '.75rem',
  lineHeight: '1rem',
  marginBottom: '.15rem',
}));

const Tooltip1DValues = styled(Typography)(() => ({
  fontSize: '.625rem',
  lineHeight: '.875rem',
  span: {
    fontWeight: 'bold',
  },
}));

const StyledSpan = styled('span')(({ theme, isActual }) => ({
  fontSize: '0.625rem',
  color: isActual && theme.palette.primary.contrast,
}));

export {
  AbsoluteBox,
  RelativeBox,
  DonutWithMetricBox,
  OEEMetricShorthand,
  OEETargets,
  StyledCircularProgress,
  GaugeGridItem,
  LineTooltipBox,
  LineTooltipTypo,
  RowFlexContainer,
  LegendContainer,
  LegendIconCircle,
  LegendLabelTypography,
  LimitsAbsoluteBox,
  LimitsTypography,
  SingleGaugeTiltleTypography,
  Tooltip1DBox,
  Tooltip1DLabel,
  Tooltip1DValues,
  StyledSpan,
};
