import { styled, Box } from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const StyledDateCalendar = styled(DatePicker)(({ theme, error }) => ({
  margin: '0.6rem 0',
  width: '100%',
  '.MuiInputBase-input': {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    padding: '0.5rem',
  },
  '& .MuiInputLabel-outlined': {
    color: error && theme.palette.error.main,
    [theme.breakpoints.up('lg')]: {
      top: '-0.3rem',
      left: '0.5rem',
    },
  },
  '& .MuiInputBase-root ': {
    '&:hover fieldset': {
      borderColor: error
        ? theme.palette.error.main
        : theme.palette.generic.borderOutlined,
    },
    '&.Mui-disabled': {
      '&:hover fieldset': {
        borderColor: theme.palette.generic.borderOutlined,
      },
    },
    '&.Mui-focused fieldset': {
      borderColor: error
        ? theme.palette.error.dark
        : theme.palette.primary.main,
    },
    '.MuiButtonBase-root > .MuiSvgIcon-root': {
      [theme.breakpoints.up('xl')]: {
        margin: '0.15rem',
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: error
        ? theme.palette.error.border.outlined
        : theme.palette.generic.borderOutlined,
      [theme.breakpoints.up('xl')]: {
        border: '0.07rem solid',
        borderRadius: '0.3rem',
        borderColor: error
          ? theme.palette.error.border.outlined
          : theme.palette.generic.borderOutlined,
      },
    },
  },
}));

const ErrorMessage = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
  marginLeft: '0.5rem',
  fontSize: '0.75rem',
  [theme.breakpoints.down('mid')]: {
    fontSize: '0.6rem',
  },
}));

const StyledSpan = styled('span')(() => ({
  fontSize: '0.75rem',
}));

// eslint-disable-next-line import/prefer-default-export
export { StyledDateCalendar, ErrorMessage, StyledSpan };
