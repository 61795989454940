import {
  DCL_CHART_FIXED_KEYS,
  DCL_CHART_FIXED_KEYS_LIST,
} from '../../../constants';

const getLineColor = (key, theme) => {
  const lineColors = {
    [DCL_CHART_FIXED_KEYS.AVERAGE]: theme.palette.charts.target,
    [DCL_CHART_FIXED_KEYS.DYNAMIC_UCL]: theme.palette.charts.thresholdRed,
    [DCL_CHART_FIXED_KEYS.DYNAMIC_LCL]: theme.palette.charts.thresholdRed,
    [DCL_CHART_FIXED_KEYS.PREDICTED_FPY]: theme.palette.charts.thresholdRed,
    [DCL_CHART_FIXED_KEYS.ACTUAL_FPY]: theme.palette.charts.thresholdAmber,
    1: theme.palette.charts.primary1,
    2: theme.palette.charts.primary2,
    3: theme.palette.charts.secondary1,
    4: theme.palette.charts.secondary2,
    5: theme.palette.charts.tertiary1,
    6: theme.palette.charts.line2,
    7: theme.palette.charts.line4,
    8: theme.palette.charts.line3,
    9: theme.palette.charts.yellow,
    10: theme.palette.charts.thresholdAmber,
  };
  return lineColors[key];
};

export const createDataset = (data, theme, getScaledValueWrapper) => {
  if (!data || (Array.isArray(data) && data.length === 0)) {
    return {
      datasets: [
        {
          data: [],
        },
      ],
      labels: [],
      xTicksCount: 0,
    };
  }
  const datasets = [];
  let longestArrLen = -1;

  data.forEach((series) => {
    const arrLen = series?.values?.length;
    if (arrLen > longestArrLen) {
      longestArrLen = arrLen;
    }

    datasets.push({
      label: series.key,
      data: series?.values,
      fill: false,
      borderColor: getLineColor(series.colorKey, theme),
      backgroundColor:
        series.key === DCL_CHART_FIXED_KEYS.DYNAMIC_LCL ||
        series.key === DCL_CHART_FIXED_KEYS.PREDICTED_FPY
          ? null
          : getLineColor(series.colorKey, theme),
      pointStyle:
        DCL_CHART_FIXED_KEYS_LIST.indexOf(series.key) > -1 ? false : 'circle',
      borderWidth: getScaledValueWrapper(2),
      ...((series.key === DCL_CHART_FIXED_KEYS.DYNAMIC_LCL ||
        series.key === DCL_CHART_FIXED_KEYS.PREDICTED_FPY) && {
        borderDash: [10, 5],
      }),
    });
  });

  const labels = Array.from({ length: longestArrLen + 1 }, (_, i) => i);

  return { datasets, labels, xTicksCount: longestArrLen / 5 + 1 };
};

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;
    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export const dclCustomLegend = {
  id: 'dclCustomLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    const items = chart.options.plugins.legend.labels.generateLabels(chart);
    items.sort((a, b) => {
      const order = ['partId', ...DCL_CHART_FIXED_KEYS_LIST];
      const indexA = order.findIndex((type) => a.text.includes(type));
      const indexB = order.findIndex((type) => b.text.includes(type));
      return indexA - indexB;
    });

    items.forEach((item) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '0.6rem';
      const boxSpan = document.createElement('span');

      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = '0.115rem';
      boxSpan.style.borderStyle =
        item.text === DCL_CHART_FIXED_KEYS.DYNAMIC_LCL ||
        item.text === DCL_CHART_FIXED_KEYS.PREDICTED_FPY
          ? 'dotted'
          : 'solid';
      boxSpan.style.display = 'inline-block';
      boxSpan.style.flexShrink = 0;
      boxSpan.style.marginRight = '0.3rem';
      boxSpan.style.width = '0.75rem';
      if (
        item.text === DCL_CHART_FIXED_KEYS.DYNAMIC_LCL ||
        item.text === DCL_CHART_FIXED_KEYS.PREDICTED_FPY
      ) {
        boxSpan.style.borderLeft = null;
        boxSpan.style.borderRight = null;
        boxSpan.style.borderBottom = null;
        boxSpan.style.height = '0px';
        boxSpan.style.borderWidth = '.2rem';
      }

      let itemToUse = item;
      if (options?.ypRca) {
        const newItem = {
          ...item,
          text: DCL_CHART_FIXED_KEYS.CONTRIBUTION_PERCENTAGE,
        };
        boxSpan.style.width = '0.5rem';
        boxSpan.style.height = '0.5rem';
        boxSpan.style.borderColor = newItem.fillStyle;
        boxSpan.style.borderWidth = '0.115rem';
        itemToUse = newItem;
      }
      boxSpan.style.background = itemToUse.fillStyle;

      const textContainer = document.createElement('p');
      textContainer.style.color = itemToUse.fontColor;
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.fontFamily = 'Open Sans';
      textContainer.style.fontSize = '0.75rem';

      const text = document.createTextNode(itemToUse.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};
