import React, { useState, useRef, useEffect } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import PropTypes from 'prop-types';
import {
  AbsoluteBox,
  GaugeGridItem,
  LimitsAbsoluteBox,
  LimitsTypography,
  SingleGaugeTiltleTypography,
  OEEMetricShorthand,
  RelativeBox,
} from '../styledPeers';
import useWindowSize from '../../../hooks/useWindowSize';
import ThemeWrapper from '../../../utils/ThemeWrapper';
import {
  getGridFrameWidth,
  getStyleProps,
  isValidMetricName,
  renderChildren,
} from './helperFunctions';
import { fillPercent, getValueWithSymbol } from '../Donut/helperFunctions';
import { CHART_TYPE, SINGLE_GAUGE_MIN_VAL } from '../../../constants';
import Tooltip1D from './tooltip1d';
import SmfTooltip from '../../SmfTooltip';

export function Pie({ fillValue, colorCode }) {
  return (
    <ResponsivePie
      data={[
        { id: 'filled', value: fillValue },
        { id: 'blank', value: 100 - fillValue },
      ]}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      startAngle={-90}
      endAngle={90}
      innerRadius={0.73}
      padAngle={0.8}
      activeOuterRadiusOffset={1}
      /**
       * fallback color for blank part
       */
      colors={[colorCode, '#BDBDBD']}
      enableArcLinkLabels={false}
      enableArcLabels={false}
      isInteractive={false}
      legends={[]}
    />
  );
}

function Gauge({
  data,
  isExpanded,
  gaugeSize,
  isShowTargets,
  symbol,
  metricName,
  isShowTooltip,
  label,
}) {
  const { value, colorCode, target, displayValue, displayTarget, isReset } =
    data;
  const fillValue = fillPercent(value, target, isReset);
  const theme = useTheme();
  const fontSize = gaugeSize?.fontSize;
  const targetValue = getValueWithSymbol(target, symbol, displayTarget);

  return (
    gaugeSize && (
      <Tooltip1D
        isShowTooltip={isShowTooltip}
        label={label}
        actualValue={getValueWithSymbol(value, symbol, displayValue, isReset)}
        targetValue={targetValue}
      >
        <RelativeBox
          sx={{
            height: `${gaugeSize.height}px`,
            width: `${gaugeSize.width}px`,
            '& path:nth-of-type(2)': {
              fill: theme.palette.grey.grey400,
            },
          }}
          data-testid="smf-gauge-container"
        >
          <Pie fillValue={fillValue} colorCode={colorCode} />
          <AbsoluteBox>
            <Typography
              variant="caption"
              component="div"
              sx={{
                color: theme.palette.text.primary,
                fontWeight: gaugeSize?.fontWeight ? gaugeSize?.fontWeight : 400,
                marginTop: `${Math.floor(gaugeSize.width / 3.3)}px`,
                fontSize: `${fontSize ?? Math.ceil(gaugeSize.width / 4)}px`,
              }}
              data-testid="smf-gauge-actual-value"
            >
              {getValueWithSymbol(value, symbol, displayValue, isReset, true)}
            </Typography>
          </AbsoluteBox>
          {metricName && (
            <LimitsAbsoluteBox isExpanded={isExpanded}>
              <LimitsTypography
                textColor={theme.palette.text.secondary}
                isExpanded={isExpanded}
                data-testid="smf-gauge-limit"
              >
                {SINGLE_GAUGE_MIN_VAL}
              </LimitsTypography>
            </LimitsAbsoluteBox>
          )}
          {metricName && (
            <LimitsAbsoluteBox isExpanded={isExpanded} isRight value={target}>
              <LimitsTypography
                textColor={theme.palette.text.secondary}
                isExpanded={isExpanded}
                data-testid="smf-gauge-limit"
              >
                {target}
              </LimitsTypography>
            </LimitsAbsoluteBox>
          )}
          {isShowTargets && (
            <AbsoluteBox>
              {isValidMetricName(metricName) ? (
                <SingleGaugeTiltleTypography
                  textColor={theme.palette.text.secondary}
                  isExpanded={isExpanded}
                  marginTop={gaugeSize.singleGaugeTitleMarginTop}
                  data-testid="smf-single-gauge-title"
                >
                  {metricName}
                </SingleGaugeTiltleTypography>
              ) : (
                <Typography
                  variant="caption"
                  component="div"
                  sx={{
                    color: theme.palette.text.secondary,
                    fontSize: isExpanded
                      ? '1.25rem'
                      : `${gaugeSize.targetFontSize}px`,
                    marginTop: `${gaugeSize?.targetMarginTop}px`,
                    mx: 'auto',
                  }}
                  data-testid="smf-gauge-target-value"
                >
                  {`(${targetValue})`}
                </Typography>
              )}
            </AbsoluteBox>
          )}
        </RelativeBox>
      </Tooltip1D>
    )
  );
}

export default function GaugeChart({
  isExpanded,
  isShowTypeLabelFull,
  isDark = false,
  data,
  gaugesMeta = [
    { fullLabel: 'OEE', shortLabel: 'OEE', key: 'oee' },
    { fullLabel: 'Availability', shortLabel: 'A', key: 'availability' },
    { fullLabel: 'Performance', shortLabel: 'P', key: 'performance' },
    { fullLabel: 'Quality', shortLabel: 'Q', key: 'quality' },
  ],
  isShowBigChartLabel = false,
  isShowTargets = true,
  symbol = { value: '%', position: 'suffix' },
  metricName,
  stylingProps,
  type = '',
  isShowTooltip = false,
  bigGaugeText,
}) {
  const bigGaugeMeta = gaugesMeta[0];
  const smallGaugesMeta = gaugesMeta.slice(1);

  const { width } = useWindowSize();

  const containerRef = React.useRef(null);
  const bigGaugeGridRef = React.useRef(null);
  const smallGaugeGridRef = React.useRef(null);
  const singleGaugeRef = React.useRef(null);

  const bigGaugeSize = getStyleProps(
    isExpanded,
    width,
    containerRef,
    bigGaugeGridRef,
    smallGaugeGridRef,
    singleGaugeRef,
    stylingProps,
    'big'
  );

  const smallGaugeSize = getStyleProps(
    isExpanded,
    width,
    containerRef,
    bigGaugeGridRef,
    smallGaugeGridRef,
    singleGaugeRef,
    stylingProps,
    'small'
  );

  const singleGaugeSize = getStyleProps(
    isExpanded,
    width,
    containerRef,
    bigGaugeGridRef,
    smallGaugeGridRef,
    singleGaugeRef,
    stylingProps,
    'single'
  );

  const oeeMetricProps = {
    fontSize: Math.min(smallGaugeSize?.targetFontSize ?? '1rem', '1.994375rem'),
    lineHeight: isExpanded ? '3rem' : '1.75rem',
    marginBottom: '-0.8rem',
  };
  let displayText;

  if (typeof bigGaugeText === 'object') {
    displayText = bigGaugeText;
  } else if (isShowTypeLabelFull) {
    displayText = bigGaugeMeta.fullLabel;
  } else {
    displayText = bigGaugeMeta.shortLabel;
  }

  const [isOverflow, setIsOverflow] = useState(false);
  const [isOverflowActual, setIsOverflowActual] = useState(false);

  const textRef = useRef(null);
  const textRefActual = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      const newIsOverflowArray = smallGaugesMeta.map((gauge) => {
        const textRefSmallGauge = textRef[gauge.key];
        if (textRefSmallGauge) {
          return textRefSmallGauge.scrollWidth > textRefSmallGauge.offsetWidth;
        }
        return false;
      });
      setIsOverflow(newIsOverflowArray);

      if (textRefActual.current) {
        setIsOverflowActual(
          textRefActual.current.scrollWidth > textRefActual.current.offsetWidth
        );
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [textRef, textRefActual]);
  return (
    <ThemeWrapper isDark={isDark}>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: type !== CHART_TYPE.SINGLE_GAUGE ? 'center' : null,
        }}
        ref={containerRef}
        data-testid="smf-gauges-wrapper"
      >
        {type === CHART_TYPE.SINGLE_GAUGE ? (
          <div
            ref={singleGaugeRef}
            data-testid="smf-single-gauge"
            style={{
              width: `100%`,
            }}
          >
            <Gauge
              data={data[bigGaugeMeta.key]}
              isExpanded={isExpanded}
              gaugeSize={singleGaugeSize}
              isShowTargets={isShowTargets}
              symbol={symbol}
              metricName={metricName}
            />
          </div>
        ) : (
          <Grid
            container
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-end',
              flexWrap: 'nowrap',
              height: 'fit-content',
              columnGap: isExpanded ? '1.5rem' : '.5rem',
              maxWidth: `${getGridFrameWidth(
                isExpanded,
                containerRef.current?.offsetWidth ?? '400'
              )}%`,
              margin: 'auto',
            }}
          >
            <GaugeGridItem xs={4} ref={bigGaugeGridRef}>
              {isShowBigChartLabel && (
                <SmfTooltip
                  placement="top"
                  disableHoverListener={!isOverflowActual}
                  title={
                    displayText.props?.children &&
                    renderChildren(displayText.props.children)
                  }
                >
                  <OEEMetricShorthand
                    {...oeeMetricProps}
                    data-testid="smf-gauge-type-label"
                    ref={textRefActual}
                    isOverflowActual={isOverflowActual}
                  >
                    {displayText}
                  </OEEMetricShorthand>
                </SmfTooltip>
              )}
              <Gauge
                isBig
                data={data[bigGaugeMeta.key]}
                isExpanded={isExpanded}
                gaugeSize={bigGaugeSize}
                isShowTargets={isShowTargets}
                symbol={symbol}
                metricName={metricName}
                isShowTooltip={isShowTooltip}
                label={bigGaugeMeta.fullLabel}
              />
            </GaugeGridItem>
            {smallGaugesMeta.map((smallGauge, idx) => (
              <GaugeGridItem
                xs={2.6}
                key={`${smallGauge.key}_${idx + 1}`}
                {...(!idx && { ref: smallGaugeGridRef })}
              >
                <SmfTooltip
                  placement="top"
                  disableHoverListener={!isOverflow[idx]}
                  key={`${smallGauge.key}_${idx + 1}`}
                  title={
                    <span style={{ fontSize: '0.625rem' }}>
                      {smallGauge.fullLabel}
                    </span>
                  }
                >
                  <OEEMetricShorthand
                    key={`${smallGauge.key}_${idx + 1}`}
                    {...oeeMetricProps}
                    data-testid="smf-gauge-type-label"
                    ref={(ref) => {
                      textRef[smallGauge.key] = ref;
                    }}
                    isOverflow={isOverflow[idx]}
                  >
                    <span>
                      {isShowTypeLabelFull
                        ? smallGauge.fullLabel
                        : smallGauge.shortLabel}
                    </span>
                  </OEEMetricShorthand>
                </SmfTooltip>

                <Gauge
                  data={data[smallGauge.key]}
                  isExpanded={isExpanded}
                  gaugeSize={smallGaugeSize}
                  isShowTargets={isShowTargets}
                  symbol={symbol}
                  isShowTooltip={isShowTooltip}
                  label={smallGauge.fullLabel}
                />
              </GaugeGridItem>
            ))}
          </Grid>
        )}
      </div>
    </ThemeWrapper>
  );
}

Gauge.propTypes = {
  data: PropTypes.instanceOf(Object),
  isExpanded: PropTypes.bool,
  gaugeSize: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    targetFontSize: PropTypes.number,
    targetMarginTop: PropTypes.number,
    singleGaugeTitleMarginTop: PropTypes.number,
    fontSize: PropTypes.number,
    fontWeight: PropTypes.number,
  }),
  isShowTargets: PropTypes.bool,
  symbol: PropTypes.shape({
    value: PropTypes.string,
    position: PropTypes.oneOf(['prefix', 'suffix']),
  }),
  metricName: PropTypes.string,
  label: PropTypes.string,
  isShowTooltip: PropTypes.bool,
};

GaugeChart.propTypes = {
  isExpanded: PropTypes.bool,
  isShowTypeLabelFull: PropTypes.bool,
  isDark: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  gaugesMeta: PropTypes.instanceOf(Array),
  isShowBigChartLabel: PropTypes.bool,
  isShowTargets: PropTypes.bool,
  symbol: PropTypes.shape({
    value: PropTypes.string,
    position: PropTypes.oneOf(['prefix', 'suffix']),
  }),
  metricName: PropTypes.string,
  stylingProps: PropTypes.instanceOf(Object),
  type: PropTypes.string,
  isShowTooltip: PropTypes.bool,
  bigGaugeText: PropTypes.bool,
};

Pie.propTypes = {
  fillValue: PropTypes.number,
  colorCode: PropTypes.string,
};
