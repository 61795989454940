import React from 'react';
import PropTypes from 'prop-types';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import {
  Box,
  Divider,
  Paper,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { getInitialConfig, globalFilterFn } from './tableUtils';
import MESSAGE_STRINGS from '../../constants/en-us';
import TableSearchField from './table-components/TableSearchField';
import TableDropDownMobileView from './table-components/TableDropDownMobileView';
import HeaderOptionsMenu from './table-components/HeaderOptionsMenu';
import HeaderSortArrow from './table-components/HeaderSortArrow';
import CustomFooterComponent from './table-components/CustomFooterComponent';
import ThemeWrapper from '../../utils/ThemeWrapper';
import useWindowSize from '../../hooks/useWindowSize';
import { getThemeColors } from '../SmfCharts/HorizontalBar/utils';
import { getScaledValue } from '../SmfCharts/Helper/helper';
import { StyledBox } from './style';
import { USE_CASE, GENERIC_CONSTANTS } from '../../constants/index';

const HeaderCell = styled(TableCell)(({ theme }) => ({
  background: theme.palette.background.darkishBlackBlue,
  padding: '.5rem',
  paddingRight: '0',
  color: theme.palette.text.lightUnitGrey,
}));

const TablePaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  background: theme.palette.background.blackGrey,
  boxShadow: 'none',
}));
const TableBodyRow = styled(TableRow)(() => ({
  height: '3rem',
}));
const TableBodyCell = styled(TableCell)(() => ({
  padding: '0 .5rem',
  borderBottom: `none`,
}));

function SMFTable({
  rows = [],
  columns = [],
  stickyHeader = true,
  maxHeight = '100%',
  minHeight = '100%',
  height = '100%',
  initialPagination = { pageIndex: 0, pageSize: 5 },
  tableName = 'smf-table',
  loading = false,
  tableConfig = {},
  customGlobalFilterFn = globalFilterFn,
  EmptySearchTableLabel = () => 'No search result found',
  FooterComponent = (props) => <CustomFooterComponent {...props} />,
  isDark = false,
  tableProps = {},
  isRowSelected = () => false,
  sortIconClicked = () => {},
  tableRef = {},
  handleScroll = () => {},
}) {
  const { tickColor } = getThemeColors(isDark);
  const { width: screenWidth } = useWindowSize();
  const getScaledValueWrapper = (value) => getScaledValue(screenWidth, value);
  const tableData = React.useMemo(
    () => (loading ? Array(5).fill({}) : rows),
    [loading, rows]
  );

  const [globalFilter, setGlobalFilter] = React.useState('');
  const [sorting, setSorting] = React.useState([]);

  const [expanded, setExpanded] = React.useState({});
  const tableColumns = React.useMemo(
    () =>
      loading
        ? columns.map((column) => ({
            ...column,
            cell: (
              <Skeleton
                height={getScaledValueWrapper(42)}
                className="smf-table-loader"
                data-testid="smf-table-skeleton"
              />
            ),
          }))
        : columns,
    [loading, columns]
  );

  const { configObj, initialConfig } = getInitialConfig(tableConfig);

  const instance = useReactTable({
    data: tableData,
    columns: tableColumns,
    initialState: {
      pagination: initialPagination,
    },
    state: { globalFilter, sorting, expanded },
    getSubRows: (row) => row.subRows,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    globalFilterFn: customGlobalFilterFn,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    ...initialConfig,
    ...tableProps,
  });
  const isEmptyLabelDisplayed =
    instance.getRowModel().rows.length === 0 &&
    (Boolean(EmptySearchTableLabel) || Boolean(configObj?.emptyRowsText));

  const { useCase } = configObj;

  let paddingTopValue;
  if (useCase === USE_CASE.WORK_ORDER) {
    paddingTopValue = '3.5rem';
  }
  if (useCase === USE_CASE.CONSTRAINT_STATUS) {
    paddingTopValue = '2.5rem';
  }
  return (
    <ThemeWrapper isDark={isDark}>
      <TablePaper
        className="smf-table-paper"
        data-testid={`${tableName}-paper`}
      >
        {configObj.search && (
          <Box marginBottom="1.5rem">
            <TableSearchField
              value={globalFilter}
              onChange={(value) => setGlobalFilter(String(value))}
              placeholder={
                configObj?.searchPlaceholder ||
                MESSAGE_STRINGS['SMFToast.placeholder.searchField']
              }
              data-testid={`${tableName}-searchfield`}
              className="smf-table-searchfield"
              sx={{
                width: '16.25rem',
                '& .tableSearchIcon': {
                  path: {
                    fill: (theme) => theme.palette.text.primary,
                  },
                },
                '& .MuiInput-underline:before': {
                  borderBottomColor: (theme) =>
                    theme.palette.generic.borderStandardInputLine,
                },
              }}
              inputProps={{ maxLength: 255 }}
            />
          </Box>
        )}
        {configObj.isMobileView && (
          <TableDropDownMobileView
            label="View"
            value={configObj.mobileViewColumn}
            options={configObj.defaultColumnOrder}
            handleChange={configObj.handleChange}
          />
        )}
        <TableContainer
          sx={{ maxHeight, height, minHeight }}
          className={`smf-table-container ${
            isDark ? 'dark-theme' : 'light-theme'
          }`}
          data-testid={`${tableName}-container`}
          ref={tableRef}
          onScroll={handleScroll}
        >
          <Table
            stickyHeader={stickyHeader}
            aria-label="table"
            className="smf-table"
            sx={{
              height: isEmptyLabelDisplayed ? '100%' : 'auto',
            }}
          >
            {!configObj.hideHeader &&
              instance.getHeaderGroups().map((headerGroup) => (
                <TableHead
                  key={headerGroup.id}
                  className="smf-table-header"
                  data-testid={`${tableName}-head`}
                >
                  <TableRow
                    className="smf-table-header-row"
                    data-testid={`${tableName}-header-row`}
                  >
                    {headerGroup.headers.map((header) => (
                      <HeaderCell
                        tabIndex={0}
                        key={header.id}
                        colSpan={header.colSpan}
                        width={header.column.columnDef.width}
                        sx={{
                          ...header.column.columnDef.sx,
                        }}
                        aria-label={header.column.columnDef.header}
                        role={GENERIC_CONSTANTS.TABLE_HEADER}
                        className="smf-table-header-cell"
                        data-testid={`${tableName}-header-cell`}
                      >
                        {header.isPlaceholder ||
                        header.column.columnDef.isEmptyHeader ? null : (
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              width: '100%',
                            }}
                          >
                            <StyledBox
                              header={header}
                              data-testid={`${tableName}-header-value`}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {configObj.sorting &&
                                header.column.getCanSort() && (
                                  <HeaderSortArrow
                                    sortIconClicked={sortIconClicked}
                                    sortHandler={header.column.getToggleSortingHandler()}
                                    selectedSort={header.column.getIsSorted()}
                                    id={header.column.id}
                                    data-testid={`${tableName}-sort-icon`}
                                    nextSortingOrder={header.column.getNextSortingOrder()}
                                    hideSortIcon={
                                      header.column.columnDef.hideSortIcon
                                    }
                                  />
                                )}
                            </StyledBox>
                            {configObj.sorting &&
                              header.column.getCanSort() && (
                                <HeaderOptionsMenu
                                  sortIconClicked={sortIconClicked}
                                  id={header.column.id}
                                  selectedSort={header.column.getIsSorted()}
                                  toggleSorting={header.column.toggleSorting}
                                  clearSorting={header.column.clearSorting}
                                />
                              )}
                            <Box>
                              <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                                sx={{
                                  height: '1rem',
                                  margin: 0,
                                  borderWidth: '.0625rem',
                                  background: tickColor,
                                }}
                              />
                            </Box>
                          </Box>
                        )}
                      </HeaderCell>
                    ))}
                  </TableRow>
                </TableHead>
              ))}
            <TableBody
              className="smf-table-body"
              data-testid={`${tableName}-body`}
              sx={{
                'tr:nth-of-type(odd)': {
                  background: (theme) => theme.palette.grey.grey100,
                },
              }}
            >
              {isEmptyLabelDisplayed ? (
                <TableBodyRow
                  sx={{ '&.smf-table': { height: '100%' } }}
                  data-testid={`${tableName}-empty-row`}
                  className="smf-table-empty-row"
                >
                  <TableBodyCell
                    sx={{
                      background: (theme) =>
                        isDark
                          ? theme.palette.common.black
                          : theme.palette.common.white,
                      paddingTop: paddingTopValue,
                    }}
                    colSpan={instance.getHeaderGroups()[0].headers.length}
                    align="center"
                    tabIndex={0}
                  >
                    {rows.length === 0 ? (
                      <p aria-label={configObj?.emptyRowsText}>
                        {configObj?.emptyRowsText}
                      </p>
                    ) : (
                      <EmptySearchTableLabel
                        aria-label={globalFilter}
                        text={globalFilter}
                      />
                    )}
                  </TableBodyCell>
                </TableBodyRow>
              ) : (
                instance.getRowModel().rows.map((row) => {
                  return (
                    <TableBodyRow
                      key={row.id}
                      className="smf-table-body-row"
                      data-testid={`${tableName}-body-row`}
                      selected={isRowSelected(row)}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableBodyCell
                            key={cell.id}
                            tabIndex={0}
                            align={cell.align}
                            className="smf-table-body-cell"
                            data-testid={`${tableName}-${cell.column.id}-body-cell`}
                            aria-label={cell.column.columnDef.cell}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableBodyCell>
                        );
                      })}
                    </TableBodyRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {configObj.footer && (
          <FooterComponent
            instance={instance}
            data-testid={`${tableName}-footer`}
          />
        )}
      </TablePaper>
    </ThemeWrapper>
  );
}

SMFTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  height: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  initialPagination: PropTypes.shape({
    pageSize: PropTypes.number,
    pageIndex: PropTypes.number,
  }),
  stickyHeader: PropTypes.bool,
  tableName: PropTypes.string,
  loading: PropTypes.bool,
  tableConfig: PropTypes.shape({}),
  emptyRowsText: PropTypes.string || PropTypes.node,
  EmptySearchTableLabel: PropTypes.node,
  FooterComponent: PropTypes.node,
  isDark: PropTypes.bool,
  customGlobalFilterFn: PropTypes.func,
  tableProps: PropTypes.shape({}),
  isRowSelected: PropTypes.func,
  sortIconClicked: PropTypes.func,
  tableRef: PropTypes.shape({}),
  handleScroll: PropTypes.func,
};

export default SMFTable;
