import PropTypes from 'prop-types';
import React from 'react';
import { StyledSwitch } from '../../utils/styles';

function ToggleSwitch({ isSwitched, setIsSwitched, ...rest }) {
  const handleToggleChange = (event) => {
    setIsSwitched(event.target.checked);
  };
  return (
    <StyledSwitch
      checked={isSwitched}
      isSwitched={isSwitched}
      onChange={handleToggleChange}
      {...rest}
    />
  );
}

export default ToggleSwitch;

ToggleSwitch.propTypes = {
  isSwitched: PropTypes.bool,
  setIsSwitched: PropTypes.func,
};
