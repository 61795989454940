import { styled, Typography } from '@mui/material';

const Container = styled('div')(() => ({ display: 'flex' }));

const Typo = styled(Typography, {
  shouldForwardProp: (p) => p !== 'isLink',
})(({ theme, isLink }) => ({
  fontWeight: 400,
  color: isLink ? theme.palette.primary.main : theme.palette.text.secondary,
  fontSize: '.75rem',
  lineHeight: '1.02125rem',
  ...(isLink && { cursor: 'pointer' }),
}));

export { Container, Typo };
