import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton, styled, Typography } from '@mui/material';
import { MESSAGE_STRINGS, TEST_IDS } from '../../constants';
import { getTimeFromTimezone } from '../../utils/helpers';
import ThemeWrapper from '../../utils/ThemeWrapper';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
}));

const PlantTimeDetailsBox = styled(Box)(() => ({
  marginLeft: '0.25rem',
}));

const typographyStyles = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontSize: '0.75rem',
  fontWeight: '400',
  lineHeight: '1rem',
};

const PlantOverviewDetailsTypography = styled(Typography)(({ theme }) => ({
  ...typographyStyles,
  fontSize: '1rem',
  lineHeight: '150%',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.secondary,
}));

export default function PlantTime({ plantTimeZone, isDark = false }) {
  const [plantTime, setPlantTime] = useState();

  useEffect(() => {
    let interval;

    const setTime = () => getTimeFromTimezone(plantTimeZone.plantLocation);

    if (plantTimeZone) {
      setPlantTime(setTime);

      interval = setInterval(() => {
        setPlantTime(setTime);
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [plantTimeZone]);

  return (
    <ThemeWrapper isDark={isDark}>
      <FlexBox data-testid={TEST_IDS.PLANT_TIME}>
        <Box>
          <PlantOverviewDetailsTypography>
            {`${MESSAGE_STRINGS.PLANT_TIME}: `}
          </PlantOverviewDetailsTypography>
        </Box>
        <PlantTimeDetailsBox>
          <PlantOverviewDetailsTypography>
            {plantTime ? (
              `${plantTime} ${plantTimeZone.plantTimeZone}`
            ) : (
              <Skeleton
                variant="text"
                animation="wave"
                width="4rem"
                data-testid={TEST_IDS.PLANT_TIME_SKELETON}
              />
            )}
          </PlantOverviewDetailsTypography>
        </PlantTimeDetailsBox>
      </FlexBox>
    </ThemeWrapper>
  );
}

PlantTime.propTypes = {
  plantTimeZone: PropTypes.shape({
    plantLocation: PropTypes.string,
    plantTimeZone: PropTypes.string,
  }).isRequired,
  isDark: PropTypes.bool,
};
