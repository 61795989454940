import {
  Button,
  CircularProgress,
  Dialog,
  Typography,
  styled,
  Container,
} from '@mui/material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  backgroundColor: theme.palette.generic.backdrop.overlay,
  boxShadow: 'none',
  '.MuiDialog-paper': {
    maxWidth: '21.875rem',
  },
}));

const StyledContainer = styled(Container)`
  position: relative;
  width: 21.875rem;
  height: 14rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledHeading = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: center;
  margin-top: 1.5rem;
`;

const StyledSubheading = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  letterSpacing: '0.009375rem',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  marginTop: '1.25rem',
  color: theme.palette.text.secondary,
}));

const StyledCancelButton = styled(Button)`
  && {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    text-transform: none;
  }
`;

export {
  StyledDialog,
  StyledContainer,
  StyledHeading,
  StyledSubheading,
  StyledCircularProgress,
  StyledCancelButton,
};
