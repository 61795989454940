import PropTypes from 'prop-types';
import React from 'react';

import MESSAGE_STRINGS from '../../constants/en-us';
import {
  StyledDialog,
  StyledContainer,
  StyledCircularProgress,
  StyledHeading,
  StyledSubheading,
  StyledCancelButton,
} from './styles';
import ThemeWrapper from '../../utils/ThemeWrapper';

export default function SmfLoadingScreenDialog({
  isOpen,
  onCancel,
  heading,
  subheading,
  isDark,
}) {
  const dialogHeading = heading || MESSAGE_STRINGS['Loading.dialog.heading'];
  const dialogSubheading =
    subheading || MESSAGE_STRINGS['Loading.dialog.subheading'];
  const dialogCancel = MESSAGE_STRINGS['Loading.dialog.cancel'];
  return (
    <ThemeWrapper isDark={isDark}>
      <StyledDialog open={isOpen} onClose={() => {}} disableEscapeKeyDown>
        <StyledContainer>
          <StyledCircularProgress size={64} />

          <StyledHeading>{dialogHeading}</StyledHeading>
          <StyledSubheading>{dialogSubheading}</StyledSubheading>
          {onCancel && (
            <StyledCancelButton
              data-testid="smf-loader-dialog-cancel-button"
              onClick={onCancel}
              variant="outlined"
            >
              {dialogCancel}
            </StyledCancelButton>
          )}
        </StyledContainer>
      </StyledDialog>
    </ThemeWrapper>
  );
}

SmfLoadingScreenDialog.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  isDark: PropTypes.bool,
};
