import React from 'react';
import PropTypes from 'prop-types';
import { MainContainer, StatusTypo } from './styles';

export default function SmfStatusDisplay({
  statusKey = 'N/A',
  statusName = 'N/A',
  statusSplitterSymbol = ':',
  statusValue = 'N/A',
  ...otherProps
}) {
  const isShowStatusValue = statusName !== 'N/A';

  return (
    <MainContainer
      statusKey={statusKey}
      data-testid="SmfStatusDisplay-main-container"
      {...otherProps}
    >
      <StatusTypo data-testid="SmfStatusDisplay-status-name">
        {statusName}
      </StatusTypo>
      {isShowStatusValue && (
        <StatusTypo
          data-testid="SmfStatusDisplay-status-value"
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          title={statusValue}
        >{`${statusSplitterSymbol} ${statusValue}`}</StatusTypo>
      )}
    </MainContainer>
  );
}

SmfStatusDisplay.propTypes = {
  /**
   * if new status introduced, we can add in below accepted list.
   */
  statusKey: PropTypes.oneOf(['N/A', 'STOP', 'RUN']),
  statusName: PropTypes.string,
  statusSplitterSymbol: PropTypes.string,
  statusValue: PropTypes.string,
};
