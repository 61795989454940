import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { line } from 'd3-shape';
import { LineTooltipBox, LineTooltipTypo } from '../styledPeers';
import ThemeWrapper from '../../../utils/ThemeWrapper';
import { formatYAxis, getScaledValue } from '../Helper/helper';
import useWindowSize from '../../../hooks/useWindowSize';

export function BarTooltip({ dataProps, tooltipProps }) {
  const { indexValue, id, data } = dataProps;
  const { xLabel } = tooltipProps;
  /**
   * `${id}_tooltipValue` property will hold already formatted tooltip value so giving first preferrence.
   */
  const tooltipValue = data[`${id}_tooltipValue`] ?? data[id];
  return (
    <LineTooltipBox data-testid="smf-bar-tooltip-box">
      <LineTooltipTypo>
        {xLabel}:{' '}
        <span data-testid="smf-bar-tooltip-indexValue">{indexValue}</span>
      </LineTooltipTypo>
      <LineTooltipTypo>
        {id}: <span data-testid="smf-bar-tooltip-value">{tooltipValue}</span>
      </LineTooltipTypo>
    </LineTooltipBox>
  );
}

BarTooltip.propTypes = {
  dataProps: PropTypes.instanceOf(Object),
  tooltipProps: PropTypes.instanceOf(Object),
};

export default function Bar({
  data,
  keys,
  yAxisValues,
  isDark = false,
  groupMode = 'grouped',
  barColors = ['#6FC2B4', '#007680'],
  tickTextColor = '#757575',
  legendTextColor = '#000000DE',
  axisBottomTextColor = '#000000DE',
  gridLineColor = '#EEEEEE',
  axisBottomTickRotation = 0,
  axisBottomLegend = '',
  legendsAnchor = 'bottom',
  legendsDirection = 'row',
  legendsTranslateX = 0,
  axisLeftLegend = '',
  axisLeftLegendOffset = -40,
  axisBottomLegendOffset = 32,
  gapBetweenBars = 0,
  gapBetweenBarGroups = 0.4,
  tooltipProps = { xLabel: 'x' },
  yAxisMaxValue = 'auto',
  unitOfMeasurement = 'units',
  yAxisMinValue = 'auto',
  ...barProps
}) {
  const { width: screenWidth } = useWindowSize();
  const getScaledValueWrapper = (value) => getScaledValue(screenWidth, value);

  return (
    <ThemeWrapper isDark={isDark}>
      <div
        style={{ height: '100%', width: '100%' }}
        data-testid="smf-bar-chart"
      >
        <ResponsiveBar
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: '.625rem',
                  fill: tickTextColor,
                },
              },
              legend: {
                text: {
                  fontSize: '.625rem',
                  fill: axisBottomTextColor,
                },
              },
            },
            legends: {
              text: {
                fontSize: '.625rem',
                fill: axisBottomTextColor,
              },
            },
            grid: {
              line: {
                stroke: gridLineColor,
                strokeWidth: getScaledValueWrapper(1),
              },
            },
          }}
          innerPadding={gapBetweenBars}
          data={data}
          keys={keys}
          indexBy="x"
          margin={{
            top: getScaledValueWrapper(10),
            right: getScaledValueWrapper(10),
            bottom: getScaledValueWrapper(70),
            left: getScaledValueWrapper(45),
          }}
          padding={gapBetweenBarGroups}
          groupMode={groupMode}
          colors={barColors}
          colorBy="id"
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          gridYValues={yAxisValues}
          axisTop={null}
          axisRight={null}
          maxValue={yAxisMaxValue}
          minValue={yAxisMinValue}
          valueScale={{ type: 'linear' }}
          axisBottom={{
            tickSize: 0,
            tickPadding: getScaledValueWrapper(5),
            tickRotation: axisBottomTickRotation,
            legend: axisBottomLegend,
            legendPosition: 'middle',
            legendOffset: axisBottomLegendOffset,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: getScaledValueWrapper(5),
            tickRotation: 0,
            tickValues: yAxisValues,
            legend: axisLeftLegend,
            legendPosition: 'middle',
            legendOffset: axisLeftLegendOffset,
            format: (v) => formatYAxis(v, unitOfMeasurement),
          }}
          enableLabel={false}
          legends={[
            {
              itemTextColor: legendTextColor,
              dataFrom: 'keys',
              anchor: legendsAnchor,
              direction: legendsDirection,
              justify: false,
              translateX: legendsTranslateX,
              translateY: getScaledValueWrapper(60),
              itemsSpacing: getScaledValueWrapper(2),
              itemWidth: getScaledValueWrapper(83),
              itemHeight: getScaledValueWrapper(20),
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: getScaledValueWrapper(11),
              symbolShape: 'circle',
            },
          ]}
          tooltip={(p) => BarTooltip({ dataProps: p, tooltipProps })}
          {...barProps}
        />
      </div>
    </ThemeWrapper>
  );
}

Bar.propTypes = {
  data: PropTypes.instanceOf(Array),
  keys: PropTypes.instanceOf(Array),
  yAxisValues: PropTypes.instanceOf(Array),
  barColors: PropTypes.instanceOf(Array),
  isDark: PropTypes.bool,
  tickTextColor: PropTypes.string,
  legendTextColor: PropTypes.string,
  axisBottomTextColor: PropTypes.string,
  gridLineColor: PropTypes.string,
  groupMode: PropTypes.oneOf(['stacked', 'grouped']),
  axisBottomTickRotation: PropTypes.number,
  axisBottomLegend: PropTypes.string,
  legendsAnchor: PropTypes.string,
  legendsDirection: PropTypes.string,
  axisLeftLegend: PropTypes.string,
  axisLeftLegendOffset: PropTypes.number,
  axisBottomLegendOffset: PropTypes.number,
  gapBetweenBars: PropTypes.number,
  gapBetweenBarGroups: PropTypes.number,
  legendsTranslateX: PropTypes.number,
  tooltipProps: PropTypes.shape({ xLabel: PropTypes.string }),
  yAxisMaxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  yAxisMinValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unitOfMeasurement: PropTypes.string,
};

// Line stretched from start to end of the graph for a given Y-Axis value
Bar.LineAcrossGraph = function LineAcrossGraph({
  bars,
  xScale,
  yScale,
  key,
  color,
  numberOfBarsPerGroup,
}) {
  const lineGenerator = line()
    .x((bar, idx) => {
      const extraBarFactor = numberOfBarsPerGroup === 1 ? 0.3 : 1;
      const extraLengthToAdd =
        idx === 0
          ? -1 * extraBarFactor * bar.width
          : bar.width * (numberOfBarsPerGroup + extraBarFactor);
      return xScale(bar.data.indexValue) + extraLengthToAdd;
    })
    .y((bar) => yScale(bar.data.data[key]));
  return (
    <path
      data-testid="smf-bar-line"
      d={lineGenerator(bars)}
      fill="none"
      stroke={color}
      style={{ pointerEvents: 'none' }}
    />
  );
};

Bar.LineAcrossGraph.propTypes = {
  bars: PropTypes.instanceOf(Array),
  xScale: PropTypes.func,
  yScale: PropTypes.func,
  key: PropTypes.instanceOf(String),
  color: PropTypes.instanceOf(String),
  numberOfBarsPerGroup: PropTypes.number,
};
