import React from 'react';
import PropTypes from 'prop-types';
import SmfErrorPage from '../SmfPage/SmfErrorPage';

export default class SmfErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error({ error, errorInfo });
  }

  render() {
    const { fallback, children, isDark } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI.
      if (fallback) {
        return fallback;
      }
      return <SmfErrorPage isDark={isDark} />;
    }
    return children;
  }
}

SmfErrorBoundary.propTypes = {
  fallback: PropTypes.elementType,
  children: PropTypes.elementType,
  isDark: PropTypes.bool,
};
