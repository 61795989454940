import { Autocomplete, styled } from '@mui/material';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '.MuiInputBase-input': {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  '& .MuiInputBase-root ': {
    '&:hover fieldset': {
      borderColor: theme.palette.text.primary,
    },
    '&.Mui-disabled': {
      '&:hover fieldset': {
        borderColor: theme.palette.generic.borderOutlined,
      },
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.generic.borderOutlined,
    },
  },
}));

// eslint-disable-next-line import/prefer-default-export
export { StyledAutocomplete };
