/**
 * @param {object} param
 * @returns
 */

function getPointSymbol({ color, datum }, isDark, getScaledValue) {
  let pointColor = datum.colorCode ?? color;
  if (datum?.colorCode === 'no_color' && isDark) {
    pointColor = '#FFFFFF';
  }
  return (
    <circle
      cx={0}
      cy={0}
      r={getScaledValue(4)}
      fill={pointColor}
      data-testid="smf-line-plot-circle"
    />
  );
}
/**
 * @param {object} param
 * @param  getScaledValue
 * @returns
 */
const getSymbolShape =
  (getScaledValue) =>
  // eslint-disable-next-line react/function-component-definition, react/prop-types
  (line) =>
    (
      <line
        x1={getScaledValue(5)}
        y1={getScaledValue(10)}
        x2={getScaledValue(20)}
        y2={getScaledValue(10)}
        stroke={line?.fill}
        strokeWidth={getScaledValue(2)}
      />
    );

/**
 * @param {object} pointParam
 * @returns Tooltip component
 */
function getToolTip(
  pointParam,
  priorityId,
  priorityDataKeys,
  LineTooltip,
  tooltipProps
) {
  const { point } = pointParam;
  /**
   * if point is of priority line return
   */
  if (point.serieId === priorityId) return LineTooltip({ point, tooltipProps });

  const tooltipTimestamp = point.data.timestamp;
  const tooltipY = point.data.y;
  const isMatchFound = priorityDataKeys.has(`${tooltipTimestamp}|${tooltipY}`);
  /**
   * if no match found with priorityDataKeys return
   */
  if (!isMatchFound) return LineTooltip({ point, tooltipProps });
  /**
   * else manipulate & set the priority id
   *
   * reason: line lib randomly showing tooltip, so regulating it with priority id
   */
  return LineTooltip({
    point: { ...point, serieId: priorityId },
    tooltipProps,
  });
}

function yValLen(yVal) {
  return `${yVal}`.length;
}

function lineMarginLeft(yLen) {
  return yLen <= 5 ? 35 : 35 + (yLen - 5) * yLen;
}

function getMargin(containerWidth) {
  if (containerWidth >= 1400)
    return {
      top: 10,
      right: 35,
      bottom: 120,
      left: 70,
    };
  return {
    top: 5,
    right: 35,
    bottom: 60,
    left: 35,
  };
}

export {
  getPointSymbol,
  getSymbolShape,
  getToolTip,
  yValLen,
  lineMarginLeft,
  getMargin,
};
