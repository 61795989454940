import React from 'react';
import { Grid, Typography, styled } from '@mui/material';

const MetricsItemGrid = styled((props) => (
  <Grid item justifyContent="center" textAlign="center" xs={3} {...props} />
))(() => ({
  marginRight: '.5rem',
  '&:last-of-type': { marginRight: 0 },
}));

const MetricNameTypo = styled(Typography)(({ theme }) => ({
  fontSize: '.875rem',
  lineHeight: '1.251rem',
  color: theme.palette.text.secondary,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

const MetricTargetTypo = styled(Typography)(({ theme }) => ({
  fontSize: '.75rem',
  lineHeight: '1.5rem',
  fontWeight: '400',
  letterSpacing: '0.025rem',
  color: theme.palette.text.secondary,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

const VerticalBar = styled(Typography)(({ theme }) => ({
  height: '.625rem',
  display: 'inline-block',
  margin: '0.45rem 0.25rem 0rem 0.40rem',
  width: '0.0625rem',
  background: theme.palette.text.primary,
}));

const MetricValueTypo = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'textColor',
})(({ theme, textColor }) => ({
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: textColor ?? theme.palette.text.primary,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export {
  MetricsItemGrid,
  MetricNameTypo,
  MetricValueTypo,
  MetricTargetTypo,
  VerticalBar,
};
