import {
  alpha,
  Card,
  CardActions,
  CardHeader,
  styled,
  Typography,
} from '@mui/material';

const WidgetWrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'relative',
}));

const WidgetChildrenWrapper = styled('div', {
  shouldForwardProp: (p) => !['isExpanded', 'expandedCardHeight'].includes(p),
})(({ isExpanded, expandedCardHeight }) => ({
  height: isExpanded ? expandedCardHeight : '11rem',
}));

const CardStyled = styled(Card)(({ theme }) => ({
  padding: 0,
  background: theme.palette.background.paper,
  borderRadius: '.25rem',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledCardActions = styled(CardActions)(() => ({
  marginTop: 'auto',
  minHeight: '1.5rem',
}));

const CardHeaderStyled = styled(CardHeader)(({ theme }) => ({
  '& .MuiCardHeader-title, .smf-widget-card-title': {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.75rem',
    color: theme.palette.text.primary,
  },
  '& .MuiCardHeader-subheader, .smf-widget-card-subheader': {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: '.875rem',
    lineHeight: '1.374rem',
  },
  '& .MuiCardHeader-content': { display: 'grid' },
  padding: '1rem 1rem 0rem 1rem',
}));

const LastUpdatedTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '.75rem',
  lineHeight: '1.245rem',
  letterSpacing: '.025rem',
  color: alpha(theme.palette.text.primary, 0.6),
  marginLeft: '.3rem',
  marginTop: '.5rem',
}));

export {
  WidgetWrapper,
  WidgetChildrenWrapper,
  CardStyled,
  StyledCardActions,
  CardHeaderStyled,
  LastUpdatedTypo,
};
