import { styled, Box, Typography, Button } from '@mui/material';

export const PageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Open Sans',
  height: 'calc(100vh - 3.5rem)',
  width: '100%',
  backgroundColor: theme.palette.background.default,
}));

export const PageHeaderTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '2.5rem',
  fontWeight: '400',
  lineHeight: '2.9375rem',
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

export const PageSubHeaderTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontWeight: '300',
  fontSize: '1.375rem',
  lineHeight: '2.2rem',
  width: '36.8125rem',
  textAlign: 'center',
  marginTop: '1rem',
  color: theme.palette.text.secondary,
}));

export const BottomButtonGroup = styled(Box)(() => ({
  marginTop: '2rem',
}));

export const PrimaryButton = styled(Button)(({ theme }) => ({
  marginRight: '1rem',
  textTransform: 'none',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '0.25rem',
  minWidth: '5.0625rem',
  height: '2.25rem',
}));

export const PrimaryButtonText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  fontWeight: '600',
  lineHeight: '1.5rem',
  textAlign: 'center',
  letterSpacing: '0.025rem',
  color: theme.palette.primary.contrast,
}));

export const SecondaryButton = styled(Button)(() => ({
  height: '2.25rem',
  fontSize: '0.875rem',
  fontWeight: '600',
  lineHeight: '1.5rem',
  textAlign: 'left',
  textTransform: 'none',
}));
