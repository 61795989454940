import PropTypes from 'prop-types';
import React from 'react';
import { StyledTooltip } from '../../utils/styles';

function SmfTooltip({ children, ...props }) {
  return (
    <StyledTooltip {...props}>
      <span>{children}</span>
    </StyledTooltip>
  );
}

export default SmfTooltip;

SmfTooltip.propTypes = {
  children: PropTypes.node,
};
