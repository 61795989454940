import React from 'react';
import PropTypes from 'prop-types';
import {
  PageBox,
  PageHeaderTitle,
  PageSubHeaderTitle,
  PrimaryButton,
  SecondaryButton,
  BottomButtonGroup,
  PrimaryButtonText,
} from './style';
import GearsIcon from '../../../assets/svgs/Gears.svg';
import ThemeWrapper from '../../../utils/ThemeWrapper';

export default function SmfErrorPage({
  headerText = 'We’re having trouble connecting. ',
  subHeaderText = 'Please try again in a few minutes.',
  primaryButtonText = 'Reload',
  primaryButtonOnClickHandler = () => {
    window.location.reload();
  },
  secondaryButtonText = '',
  secondaryButtonOnClickHandler = () => {},
  isDark,
}) {
  return (
    <ThemeWrapper isDark={isDark}>
      <PageBox>
        <GearsIcon
          height="8.461rem"
          width="9.313rem"
          data-testid="smf-error-page-logo"
        />
        <PageHeaderTitle data-testid="smf-error-page-header">
          {headerText}
        </PageHeaderTitle>
        <PageSubHeaderTitle data-testid="smf-error-page-sub-header">
          {subHeaderText}
        </PageSubHeaderTitle>
        <BottomButtonGroup>
          <PrimaryButton
            variant="contained"
            onClick={() => primaryButtonOnClickHandler()}
            data-testid="smf-error-page-primary-btn"
          >
            <PrimaryButtonText>{primaryButtonText}</PrimaryButtonText>
          </PrimaryButton>
          {secondaryButtonText && (
            <SecondaryButton
              variant="outlined"
              onClick={() => secondaryButtonOnClickHandler()}
              data-testid="smf-error-page-secondary-btn"
            >
              {secondaryButtonText}
            </SecondaryButton>
          )}
        </BottomButtonGroup>
      </PageBox>
    </ThemeWrapper>
  );
}

SmfErrorPage.propTypes = {
  headerText: PropTypes.string,
  subHeaderText: PropTypes.string,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  primaryButtonOnClickHandler: PropTypes.func,
  secondaryButtonOnClickHandler: PropTypes.func,
  isDark: PropTypes.bool,
};
