// eslint-disable-next-line import/prefer-default-export
export const customBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    mid: 1010,
    lg: 1900,
    xl: 3840,
  },
};
