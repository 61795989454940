import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import SmfTooltip from '../SmfTooltip';

export default function EllipsizedText({ text, className }) {
  const [isEllipsized, setIsEllipsized] = React.useState(false);
  const textRef = React.createRef();
  React.useEffect(() => {
    setIsEllipsized(textRef.current.offsetWidth < textRef.current.scrollWidth);
  }, [text, textRef]);

  return (
    <SmfTooltip
      title={text}
      placement="bottom"
      disableHoverListener={!isEllipsized}
      disableFocusListener={!isEllipsized}
      data-testid="smf-widget-card-ellipsized-tooltip"
    >
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...(isEllipsized && { cursor: 'pointer' }),
        }}
        ref={textRef}
        data-testid="smf-widget-card-ellipsized-text"
        className={className}
        tabIndex={0}
      >
        {text}
      </Typography>
    </SmfTooltip>
  );
}

EllipsizedText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};
