import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { IconContainer } from './style';
import SearchIcon from '../../assets/svgs/SearchIcon.svg';
import ClearIcon from '../../assets/svgs/ClearIcon.svg';

export default function SearchField({
  value: initialValue,
  onChange,
  debounce = 100,
  ...props
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <TextField
      data-testId="smf-search-list-serach-field"
      {...props}
      variant="standard"
      value={value}
      placeholder="Search..."
      sx={{
        width: '100%',
        '& .searchIcons': {
          path: {
            fill: (theme) => theme.palette.text.primary,
          },
        },
        '& .MuiInput-underline:before': {
          borderBottomColor: (theme) =>
            theme.palette.generic.borderStandardInputLine,
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconContainer>
              <SearchIcon className="searchIcons" />
            </IconContainer>
          </InputAdornment>
        ),
        endAdornment:
          value !== '' ? (
            <InputAdornment position="end">
              <IconButton onClick={() => onChange('')} sx={{ padding: 0 }}>
                <ClearIcon
                  height=".8rem"
                  width=".8rem"
                  data-testid="searchfield-clear-icon"
                  className="searchIcons"
                />
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

SearchField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  debounce: PropTypes.number,
};
