import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import CustomBarGraph from './SmfCustomBarGraph';

import ThemeWrapper from '../../utils/ThemeWrapper';

function SmfSingleHorizontalBarChart({
  height = '.625rem',
  width = '100%',
  target = 0,
  markObj = {},
  targetUnits = 0,
  colorMapping = {
    calculatedTarget: {
      getColor: (theme) => theme.palette.secondary.dark,
      label: 'Target',
    },
    calculatedMaxDemonstrated: {
      getColor: (theme) => theme.palette.secondary.light,
      label: 'Max demonstrated',
    },
    calculatedMaxTheoritical: {
      getColor: (theme) => theme.palette.primary.light,
      label: 'Max theoretical',
    },
  },
  isDark,
  getPercentageColor,
  caption = 'of target for current shift',
  isTooltipEnabled = true,
  hideLegend = false,
}) {
  const getPercentOfTarget = () => {
    if (!targetUnits && !target) return '-';
    if ([`${targetUnits}`, `${target}`].includes('N/A') || `${target}` === '-')
      return `-% ${caption}`;
    if (!target) return `N/A ${caption}`;
    return `${((100 * targetUnits) / target)?.toFixed(0)}% ${caption}`;
  };
  const isMarkEnabled = Boolean(Object.keys(markObj)?.length);
  return (
    <ThemeWrapper isDark={isDark}>
      <Box className="smf-shb-main-container">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          className="smf-shb-chart-detail-container"
        >
          <Box width="100%" className="smf-shb-bar-container">
            <Box
              display="flex"
              alignItems="flex-end"
              sx={{ color: (theme) => theme.palette.text.primary }}
              className="smf-single-bar-text-units"
              data-testid="smf-single-bar-text-units"
            >
              <Typography sx={{ fontWeight: 700, fontSize: '1.25rem' }}>
                {targetUnits}{' '}
              </Typography>
              <Typography
                sx={{
                  ml: (theme) => (theme.breakpoints.up('lg') ? 0.75 : 0.5),
                  mb: 0.5,
                  fontSize: '.75rem',
                  color: (theme) => theme.palette.grey.grey10,
                }}
              >
                units
              </Typography>
            </Box>
            <CustomBarGraph
              targetUnits={targetUnits}
              height={height}
              width={width}
              target={target}
              markObj={markObj}
              colorMapping={colorMapping}
              isDark={isDark}
              getPercentageColor={getPercentageColor}
              isTooltipEnabled={isTooltipEnabled}
              offSetMargin={isMarkEnabled ? '.75rem' : '.25rem'}
            />
            <Box
              display="flex"
              marginTop="0.5rem"
              className="smf-single-bar-text-percentage"
              data-testid="smf-single-bar-text-percentage"
            >
              <Typography
                variant="caption"
                sx={{
                  color: (theme) => theme.palette.grey.grey10,
                  marginLeft: '.25rem',
                }}
              >
                {getPercentOfTarget()}
              </Typography>
            </Box>
          </Box>
        </Box>
        {!hideLegend && (
          <Box
            display="flex"
            flexDirection="column"
            marginTop=".5rem"
            className="smf-shb-legend-container"
          >
            {Object.keys(markObj).map((key) => {
              return (
                <Box
                  key={`${colorMapping[key]?.label}`}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  paddingTop=".2rem"
                >
                  <Box
                    component="span"
                    sx={{
                      marginRight: '.5rem',
                      display: 'block',
                      height,
                      width: height,
                      borderRadius: '50%',
                      backgroundColor: (theme) =>
                        colorMapping[key]?.getColor(theme),
                    }}
                  />
                  <Typography
                    variant="caption"
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                  >
                    {colorMapping[key]?.label}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </ThemeWrapper>
  );
}

SmfSingleHorizontalBarChart.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  target: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  targetUnits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  markObj: PropTypes.shape({}),
  colorMapping: PropTypes.shape({}),
  isDark: PropTypes.bool,
  getPercentageColor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  caption: PropTypes.string,
  isTooltipEnabled: PropTypes.bool,
  hideLegend: PropTypes.bool,
};

export default SmfSingleHorizontalBarChart;
