import styled from '@emotion/styled';
import { InputLabel, MenuItem, Select } from '@mui/material';

const getErrorBorderColor = (theme, isError) =>
  isError ? theme.palette.error.main : theme.palette.generic.borderOutlined;
const getFocusedBorderErrorColor = (theme, isError) =>
  isError ? theme.palette.error.dark : theme.palette.primary.main;
const getNotchedBorderErrorColor = (theme, isError) =>
  isError
    ? theme.palette.error.border.outlined
    : theme.palette.generic.borderOutlined;

export const StyledSelect = styled(Select, {
  shouldForwardProp: (p) => p !== 'isError',
})(({ theme, isError }) => ({
  borderRadius: '.25rem',
  '.MuiInputBase-input': {
    color: theme.palette.text.primary,
    padding: '0.531rem 0.875rem',
  },
  '.MuiInputBase-root': {
    '&:hover fieldset': {
      borderColor: getErrorBorderColor(theme, isError),
    },
    '&.Mui-focused fieldset': {
      borderColor: getFocusedBorderErrorColor(theme, isError),
    },
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderRadius: '.25rem',
    borderColor: getNotchedBorderErrorColor(theme, isError),
    borderWidth: '0.063rem',
  },
  '.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
    paddingRight: '2rem',
  },
}));
export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '.MuiFormLabel-root': {
    transform: 'translate(.875rem, 0.563rem) scale(1)',
  },
  '.Mui-focused': {
    color: theme.palette.primary.main,
    transform: 'translate(.875rem, -0.563rem) scale(0.75)',
  },
}));
export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrast,
  color: theme.palette.text.primary,
  padding: '0.5rem 1rem',
  '.Mui-selected': {
    backgroundColor: theme.palette.grey.grey100,
    color: theme.palette.text.disabled,
    '&:hover': {
      backgroundColor: theme.palette.generic.hoverSelectedDropdownBackground,
    },
  },
}));
