import React, { useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip } from 'chart.js/auto';
import { Box } from '@mui/material';
import { max, min } from 'lodash';
import annotationPlugin from 'chartjs-plugin-annotation';
import ThemeWrapper from '../../utils/ThemeWrapper';
import CurrentMean from '../../assets/svgs/CurrentMean.svg';
import FutureMean from '../../assets/svgs/FutureMean.svg';
import Stdev from '../../assets/svgs/Stdev.svg';
import { StyledLabels, StyledLabelsBox } from './style.js';
import {
  xAxisLabel,
  tooltipCustom,
  barColor,
  horizontalBoxChartConstant,
  chartLinesColor,
  getTooltipData,
  tooltipCallback,
  afterBuildTicks,
  emptyGraphDataFunction,
} from './utils.js';
import useWindowSize from '../../hooks/useWindowSize';
import { getScaledValue } from '../SmfCharts/Helper/helper.js';

ChartJS.register(annotationPlugin, Tooltip);
export default function SmfHorizontalBoxChart({
  isDark,
  currentMean,
  futureMean,
  currentStandardDeviation,
  futureStandardDeviation,
  lsl,
  usl,
  nominal,
  isEmptyGraph,
}) {
  const { width: screenWidth } = useWindowSize();
  const getScaledValueWrapper = (value) => getScaledValue(screenWidth, value);
  const { labelColor } = xAxisLabel(isDark);
  const {
    lslLineColor,
    uslLineColor,
    nominalLineColor,
    middleLineColor,
    currentMeanLineColor,
    futureMeanLineColor,
  } = chartLinesColor(isDark);
  const { backgroundColorForTooltip, fontColor } = tooltipCustom(isDark);
  const isCurrentMeanUndefined = currentMean === undefined;
  const isFutureMeanUndefined = futureMean === undefined;
  const maxValue = max([
    currentMean + currentStandardDeviation,
    futureMean + futureStandardDeviation,
    lsl,
    usl,
    nominal,
  ]);
  const minValue = min([
    currentMean - currentStandardDeviation,
    futureMean - futureStandardDeviation,
    lsl,
    usl,
    nominal,
  ]);
  const chartRef = useRef();
  const data = [
    { label: '' },
    { label: '' },
    {
      label: '',
      value: isCurrentMeanUndefined
        ? undefined
        : [
            currentMean - currentStandardDeviation,
            currentMean + currentStandardDeviation,
          ],
      toolTipLabel: horizontalBoxChartConstant.currentMean,
      mean: currentMean,
      stdev: currentStandardDeviation,
    },
    {
      label: '',
      value: isFutureMeanUndefined
        ? undefined
        : [
            futureMean - futureStandardDeviation,
            futureMean + futureStandardDeviation,
          ],
      toolTipLabel: horizontalBoxChartConstant.futureMean,
      mean: futureMean,
      stdev: futureStandardDeviation,
    },
    { label: '' },
  ];
  const chartData = {
    labels: data?.map((d) => d.label),
    datasets: [
      {
        label: '',
        data: data?.map((d) => d.value),
        backgroundColor: ['', '', barColor.currentBar, barColor.futureBar, ''],
      },
    ],
  };
  const commonDataAndLabelOptions = {
    interaction: {
      mode: 'index',
    },
    maintainAspectRatio: false,
    indexAxis: 'y',
    plugins: {
      dclCustomLegend: false,
      barInteractionPlugin: false,
      tooltip: {
        backgroundColor: backgroundColorForTooltip,
        titleColor: fontColor,
        titleFont: {
          family: 'Open Sans',
          weight: 400,
          size: getScaledValueWrapper(10),
          style: 'normal',
        },
        callbacks: {
          title(tooltipItem) {
            return getTooltipData(tooltipItem, data);
          },
          label() {
            return null;
          },
        },
      },
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          lsl: {
            drawTime: 'beforeDatasetsDraw',
            type: 'line',
            xMin: lsl,
            xMax: lsl,
            borderColor: lslLineColor,
            borderWidth: getScaledValueWrapper(2),
            lineWidth: getScaledValueWrapper(1),
          },
          nominal: {
            drawTime: 'beforeDatasetsDraw',
            type: 'line',
            xMin: nominal,
            xMax: nominal,
            borderColor: nominalLineColor,
            borderWidth: getScaledValueWrapper(2),
            lineWidth: getScaledValueWrapper(1),
          },
          usl: {
            drawTime: 'beforeDatasetsDraw',
            type: 'line',
            xMin: usl,
            xMax: usl,
            borderColor: uslLineColor,
            borderWidth: getScaledValueWrapper(2),
            lineWidth: getScaledValueWrapper(1),
          },
          currentMeanLine: isCurrentMeanUndefined
            ? undefined
            : {
                type: 'line',
                xMin: currentMean,
                xMax: currentMean,
                yMin: 0.5,
                yMax: 4,
                borderColor: currentMeanLineColor,
                borderWidth: getScaledValueWrapper(4.5),
                lineWidth: getScaledValueWrapper(1),
              },
          middleLine: {
            drawTime: 'beforeDatasetsDraw',
            type: 'line',
            yMin: 2,
            yMax: 2,
            borderColor: middleLineColor,
            borderWidth: getScaledValueWrapper(1),
            lineWidth: getScaledValueWrapper(1),
            z: 100,
          },
          futureMeanLine: isFutureMeanUndefined
            ? undefined
            : {
                type: 'line',
                xMin: futureMean,
                xMax: futureMean,
                yMin: 1,
                yMax: 5,
                borderColor: futureMeanLineColor,
                borderWidth: getScaledValueWrapper(4.5),
                lineWidth: getScaledValueWrapper(1),
              },
        },
      },
    },
  };
  const optionsForDataChart = {
    barPercentage: 1,
    scales: {
      x: {
        min: minValue,
        max: maxValue,
        position: 'top',
        beginAtZero: false,
        ticks: {
          font: {
            size: getScaledValueWrapper(14),
            weight: 400,
          },
          color: labelColor,
          callback: (value) => tooltipCallback(value, lsl, usl, nominal),
        },
        afterBuildTicks: (scale) => afterBuildTicks(scale, lsl, usl, nominal),
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x1: {
        display: true,
        beginAtZero: false,
        min: minValue,
        max: maxValue,
        afterBuildTicks: (scale) => afterBuildTicks(scale, lsl, usl, nominal),
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: getScaledValueWrapper(10),
            weight: 400,
          },
          color: labelColor,
        },
      },
      y: {
        beginAtZero: false,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    ...commonDataAndLabelOptions,
  };

  const tempData = data?.map((d) => d.value);
  tempData.push([lsl, usl, nominal]);
  return (
    <ThemeWrapper isDark={isDark}>
      <Box data-testid="smf-horizontal-box-chart">
        <Box style={{ height: '12rem' }}>
          <Bar
            ref={chartRef}
            data={isEmptyGraph ? emptyGraphDataFunction(tempData) : chartData}
            options={optionsForDataChart}
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            justifyContent: 'center',
          }}
          data-testid="chart-label"
        >
          <StyledLabelsBox>
            <CurrentMean style={{ width: '0.5rem', height: '0.125rem' }} />
            <StyledLabels>
              {horizontalBoxChartConstant.currentMean}
            </StyledLabels>
          </StyledLabelsBox>
          <StyledLabelsBox>
            <FutureMean style={{ width: '0.5rem', height: '0.125rem' }} />
            <StyledLabels>{horizontalBoxChartConstant.futureMean}</StyledLabels>
          </StyledLabelsBox>
          <StyledLabelsBox>
            <Stdev style={{ width: '0.5rem', height: '0.5rem' }} />
            <StyledLabels>
              {horizontalBoxChartConstant.plusMinusStdev}
            </StyledLabels>
          </StyledLabelsBox>
        </Box>
      </Box>
    </ThemeWrapper>
  );
}

SmfHorizontalBoxChart.propTypes = {
  isDark: PropTypes.bool,
  currentMean: PropTypes.number,
  futureMean: PropTypes.number,
  currentStandardDeviation: PropTypes.number,
  futureStandardDeviation: PropTypes.number,
  lsl: PropTypes.number,
  usl: PropTypes.number,
  nominal: PropTypes.number,
  isEmptyGraph: PropTypes.bool,
};
