import { styled, Box } from '@mui/material';

export const StyledBox = styled(Box)(({ theme, header }) => ({
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.875rem',
  },
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden',
  flexGrow: 1,
  [`&:hover .changeVisibility${header.id}`]: {
    visibility: 'visible',
  },
  [`.changeOpacity${header.id}`]: {
    opacity: header.column.getIsSorted() === false ? '0.5' : 1,
  },
}));
