import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Typo } from './styles';

function Delimiter() {
  return (
    <Typo sx={{ margin: '0 .25rem' }} data-testid="smf-breadcrumbs-delimiter">
      /
    </Typo>
  );
}

function Breadcrumbs({ linksList, callback }) {
  const navigate = useNavigate();

  const handleNavigation = (args) => {
    if (callback) callback(args);
    navigate(args.path);
  };

  return (
    <Container data-testid="smf-breadcrumbs-container">
      {linksList.map(({ label = '', path = '', ...others }, idx) => (
        <Fragment key={`${idx + 1}-${path}`}>
          {!!idx && <Delimiter />}
          <Typo
            {...{
              'data-testid': `smf-breadcrumbs-${label
                .toLowerCase()
                .replace(/\s+/g, '-')}-link`,
              ...(idx < linksList.length - 1 && {
                isLink: true,
                onClick: () => {
                  handleNavigation({ label, path, ...others });
                },
              }),
            }}
          >
            {label}
          </Typo>
        </Fragment>
      ))}
    </Container>
  );
}

Breadcrumbs.propTypes = {
  linksList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  callback: PropTypes.func,
};

export default Breadcrumbs;
