import React from 'react';
import PropTypes from 'prop-types';
import DefaultIcon from './svg/plantIcon.svg';
import { IconContainer, ItemTypography, StyledListItem } from './style';

export default function ListItem({
  text,
  onClick = () => {},
  hideIcon = false,
  isSelected = false,
}) {
  return (
    <StyledListItem onClick={onClick} isSelected={isSelected}>
      {!hideIcon && (
        <IconContainer sx={{ paddingRight: '1rem' }}>
          <DefaultIcon
            width="1.75rem"
            height="1.5rem"
            data-testid="SmfSearchList-add-button"
          />
        </IconContainer>
      )}
      <ItemTypography>{text}</ItemTypography>
    </StyledListItem>
  );
}

ListItem.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  hideIcon: PropTypes.bool,
  isSelected: PropTypes.bool,
};
