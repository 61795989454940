import PropTypes from 'prop-types';
import { styled, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.009rem',
  color: theme.palette.text.primary,
}));

export default function TableDropDownMobileView({
  label,
  value,
  options,
  handleChange,
}) {
  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 120,
        marginLeft: '0rem !important',
        marginRight: '0rem !important',
        width: '100%',
      }}
      size="small"
      data-testid="dropdown-table-form"
    >
      <InputLabel id="dropdown-mobile">{label}</InputLabel>
      <Select
        MenuProps={{
          sx: {
            '& .Mui-selected': {
              backgroundColor: 'inherit',
            },
          },
        }}
        sx={{
          color: (theme) => theme.palette.text.primary,
        }}
        labelId="dropdown-mobile"
        id="dropdown-mobile"
        value={value}
        label={label}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem
            key={`menuItem-${option}`}
            value={option}
            disabled={option === value}
          >
            <CustomTypography>{option}</CustomTypography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

TableDropDownMobileView.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  handleChange: PropTypes.func,
};
