const MESSAGE_STRINGS = {
  // SmfTable
  'SMFToast.placeholder.searchField': 'Search...',
  'SMFToast.options.sort.asc': 'Sort by ascending',
  'SMFToast.options.sort.desc': 'Sort by descending',
  'SMFToast.options.sort.unsort': 'Unsort',
  // Time Picker

  // Toggle
  'Toggle.default.title': 'Availability: ',
  'Toggle.default.left.option': 'Planned',
  'Toggle.default.right.option': 'Max',
  'Toggle.default.tooltip.text': 'Display OEE using either',
  'Toggle.default.tooltip.boldTextPlanned': 'planned availability',
  'Toggle.default.tooltip.plannedText':
    '(i.e., when planned downtime is factored in) or',
  'Toggle.default.tooltip.boldTextMax': 'max availability',
  'Toggle.default.tooltip.maxText':
    ' (i.e., when the factory is running at maximum capacity for the designated time frame with no planned downtime).',
  'Loading.dialog.heading': 'Please wait...',
  'Loading.dialog.subheading': 'This may take a moment.',
  'Loading.dialog.cancel': 'Cancel',
};

export default MESSAGE_STRINGS;
