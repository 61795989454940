import React from 'react';
import { useTheme, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { AbsoluteBox, RelativeBox } from '../styledPeers';
import { fillPercent, getValueWithSymbol } from '../Donut/helperFunctions';
import { Pie } from '.';

export default function SingleGaugeChart({
  data,
  metricName,
  symbol,
  gaugeSize,
}) {
  const value = data?.[metricName]?.value;
  const colorCode = data?.[metricName]?.colorCode;
  const target = data?.[metricName]?.target;
  const isReset = data?.[metricName]?.isReset;
  const displayValue = data?.[metricName]?.displayValue;
  const fillValue = fillPercent(value, target, isReset);
  const theme = useTheme();
  return (
    <RelativeBox
      sx={{
        height: gaugeSize.height,
        width: gaugeSize.width,
        '& path:nth-of-type(2)': {
          fill: theme.palette.grey.grey400,
        },
      }}
      data-testid="smf-single-gauge-container"
    >
      <Pie fillValue={fillValue} colorCode={colorCode} />
      <AbsoluteBox>
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontWeight: 400,
            marginTop: gaugeSize.marginTop,
            fontSize: gaugeSize.fontSize,
          }}
          variant="caption"
          component="div"
          data-testid="smf-single-gauge-actual-value"
        >
          {getValueWithSymbol(value, symbol, displayValue, isReset, true)}
        </Typography>
      </AbsoluteBox>
    </RelativeBox>
  );
}

SingleGaugeChart.propTypes = {
  data: PropTypes.instanceOf(Object),
  metricName: PropTypes.string,
  symbol: PropTypes.instanceOf(Object),
  gaugeSize: PropTypes.instanceOf(Object),
};
