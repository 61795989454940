import { WIDGET_NAME } from '../constants/index';

const getTimeFromTimezone = (timeZone) => {
  if (!timeZone) return '';

  const datetime = new Date().toLocaleString('en-US', { timeZone });
  const date = new Date(datetime);
  let hours = date.getHours().toString();
  let minutes = date.getMinutes().toString();
  hours = hours.length === 1 ? `0${hours}` : hours;
  minutes = minutes.length === 1 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
};

const isEqual = (valueA, valueB) => {
  return valueA === valueB;
};
const checkAndOperator = (a, b) => {
  return a && b;
};

function orCheck(mainItem, fallbackItem) {
  return mainItem || fallbackItem;
}

const measureTextWidth = (value, fontSize) => {
  const canvas = document.createElement('canvas');
  if (!canvas) {
    return 0; // Error handling
  }
  const context = canvas.getContext('2d');
  if (!context) {
    return 0; // Error handling
  }
  document.body.appendChild(canvas);
  const font = `${fontSize} Open Sans`; // Font style of label is fixed
  context.font = font;
  const metrics = context.measureText(value);
  document.body.removeChild(canvas);
  return metrics.width;
};

// Function to truncate the value when it exceeds the maximum width
const truncateTextToFit = (value, maxWidth) => {
  let truncatedText = value;
  const fontSize = '0.56rem'; // Font size of label is fixed
  let textWidth = measureTextWidth(value, fontSize);
  if (textWidth > maxWidth && textWidth > 0) {
    let index = value.length - 1;
    while (textWidth > maxWidth && index >= 0) {
      truncatedText = `${value.slice(0, index)}...`;
      textWidth = measureTextWidth(truncatedText, fontSize);
      index -= 1;
    }
  }
  return truncatedText;
};

const formatAxisLabel = (v, chartWidth, data, widgetType, isExpanded) => {
  let label = v;
  if (widgetType === WIDGET_NAME.LINE_BALANCING) {
    const value = data?.find((item) => item.entityHierarchy === v);
    label = orCheck(value?.entityName, '');
  }
  const spaceDiff = isExpanded ? 0.02 * chartWidth : 0.05 * chartWidth;
  const maxWidth = chartWidth / data.length - spaceDiff;
  const truncatedLabel =
    widgetType === WIDGET_NAME.THROUGHPUT
      ? label
      : truncateTextToFit(label, maxWidth);

  return (
    <tspan style={{ fontSize: '0.56rem' }}>
      {truncatedLabel.includes('...') && <title>{label}</title>}
      {truncatedLabel}
    </tspan>
  );
};

export {
  getTimeFromTimezone,
  isEqual,
  checkAndOperator,
  measureTextWidth,
  formatAxisLabel,
  truncateTextToFit,
  orCheck,
};
