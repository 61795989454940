import React, { useState, useEffect, createRef } from 'react';
import { PropTypes } from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { CircularProgress } from '@mui/material';
import {
  StyledUploadImageBox,
  StyledUploadImageText,
  CustomBackdrop,
  CustomBox,
  StyledReUploadButton,
  StyledUploadIconButton,
  SvgContainer,
  FileContainer,
  OuterContainer,
  FilenameTypography,
} from './styles';
import ThemeWrapper from '../../utils/ThemeWrapper';
import UploadImageButton from '../../assets/svgs/UploadImageButton.svg';
import { UPLOAD_IMAGE_CONSTANTS } from '../../constants';
import FileImage from './svgs/FileImage.svg';
import DeleteIcon from './svgs/DeleteIcon.svg';
import SmfTooltip from '../SmfTooltip';

function UploadImage({
  isLoading,
  isDark,
  getUploadUrl,
  onRemoveFile,
  isReuploadButtonVisible,
  skuImageUrl,
  requiredFileTypeObject,
  isDisabled,
  onWrongFileSelected,
  boxStyle = {},
  UploadedFile = {
    fileName: '',
    fileSize: '',
  },
  title,
  reUploadTitle,
}) {
  const [isEllipsized, setIsEllipsized] = useState(false);
  const textRef = createRef();

  useEffect(() => {
    const updateEllipsizedState = () => {
      if (textRef.current) {
        setIsEllipsized(
          textRef.current.offsetWidth < textRef.current.scrollWidth
        );
      }
    };

    updateEllipsizedState();

    window.addEventListener('resize', updateEllipsizedState);

    return () => {
      window.removeEventListener('resize', updateEllipsizedState);
    };
  }, [UploadedFile.fileName, textRef]);

  function onDropAccepted(acceptedFilesData) {
    getUploadUrl(acceptedFilesData[0]);
  }
  function getDropzoneProps(isPropEnabled, getRootProps) {
    return isPropEnabled ? getRootProps({ className: 'dropzone' }) : {};
  }
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted,
    onDropRejected: onWrongFileSelected,
    accept: requiredFileTypeObject,
    disabled: isDisabled,
  });
  if (isReuploadButtonVisible) {
    return (
      <ThemeWrapper isDark={isDark}>
        <StyledReUploadButton
          data-testid="smf-upload-image-reupload-button"
          variant="outlined"
          disabled={skuImageUrl === '' || isLoading}
          {...getDropzoneProps(!isLoading, getRootProps)}
        >
          <input {...getInputProps()} />
          {reUploadTitle || UPLOAD_IMAGE_CONSTANTS.REUPLOAD_IMAGE}
        </StyledReUploadButton>
      </ThemeWrapper>
    );
  }

  if (UploadedFile.fileName) {
    return (
      <ThemeWrapper isDark={isDark}>
        <OuterContainer data-testid="smf-upload-image-uploaded-file">
          <FileContainer display="flex">
            <SvgContainer sx={{ paddingLeft: '0.75rem' }}>
              <FileImage width="1.125rem" height="1.625rem" />
            </SvgContainer>
            <SmfTooltip
              title={UploadedFile.fileName}
              disableHoverListener={!isEllipsized}
            >
              <FilenameTypography
                ref={textRef}
                sx={{ ...(isEllipsized && { cursor: 'pointer' }) }}
                data-testid="smf-upload-image-filename"
              >
                {UploadedFile.fileName}
              </FilenameTypography>
            </SmfTooltip>
            <SvgContainer onClick={onRemoveFile}>
              <DeleteIcon width="1.5rem" height="1.5rem" />
            </SvgContainer>
          </FileContainer>
        </OuterContainer>
      </ThemeWrapper>
    );
  }

  return (
    <ThemeWrapper isDark={isDark}>
      <StyledUploadImageBox
        data-testid="smf-upload-image"
        isLoading={isLoading}
        isDisabled={isDisabled}
        boxStyle={boxStyle}
        {...getDropzoneProps(!isLoading, getRootProps)}
      >
        {!isLoading && (
          <>
            <input {...getInputProps()} />
            <StyledUploadIconButton isDisabled={isDisabled}>
              <UploadImageButton
                className="upload-button"
                data-testid="smf-upload-image-upload-button"
                width="3.175rem"
                height="3.175rem"
              />
            </StyledUploadIconButton>
            <StyledUploadImageText isDisabled={isDisabled}>
              {title || UPLOAD_IMAGE_CONSTANTS.UPLOAD_IMAGE}
            </StyledUploadImageText>
          </>
        )}
        {isLoading && (
          <>
            <CustomBackdrop invisible open />
            <CustomBox>
              <CircularProgress
                color="primary"
                size={32}
                data-testid="smf-upload-image-circular-progress"
              />
            </CustomBox>
          </>
        )}
      </StyledUploadImageBox>
    </ThemeWrapper>
  );
}

export default UploadImage;

UploadImage.propTypes = {
  isLoading: PropTypes.bool,
  isDark: PropTypes.bool,
  getUploadUrl: PropTypes.func,
  onRemoveFile: PropTypes.func,
  isReuploadButtonVisible: PropTypes.bool,
  skuImageUrl: PropTypes.string,
  requiredFileTypeObject: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  onWrongFileSelected: PropTypes.func,
  boxStyle: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  UploadedFile: PropTypes.shape({
    fileName: PropTypes.string,
    fileSize: PropTypes.string,
  }),
  title: PropTypes.string,
  reUploadTitle: PropTypes.string,
};
