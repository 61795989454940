import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactDOM from 'react-dom/client';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  // eslint-disable-next-line no-unused-vars
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
  renderType: 'createRoot',
});

export { default as lightTheme } from './theme/theme-light';
export { default as darkTheme } from './theme/theme-dark';

export { default as SmfAlert } from './shared-components/SmfAlert';
export { default as SmfTable } from './shared-components/SmfTable';
export { default as SmfAutocomplete } from './shared-components/SmfAutocomplete';
export { default as SmfDateCalendar } from './shared-components/SmfDateCalendar';
export { default as SmfToggle } from './shared-components/SmfToggle';

export { default as SmfSingleHorizontalBarChart } from './shared-components/SmfSingleHorizontalBar';
export { default as SmfHorizontalBoxChart } from './shared-components/SmfHorizontalBoxChart';
export { default as SmfCustomBarGraph } from './shared-components/SmfSingleHorizontalBar/SmfCustomBarGraph';

export { default as SmfWidget } from './shared-components/SmfWidget';
export { useWidgetIsExpanded } from './shared-components/SmfWidget';

export { default as SmfDonut } from './shared-components/SmfCharts/Donut';
export { default as SmfGauge } from './shared-components/SmfCharts/Gauge';
export { default as SmfSingleGauge } from './shared-components/SmfCharts/Gauge/SingleGauge';
export { default as SmfLine } from './shared-components/SmfCharts/Line';
export { default as SmfBar } from './shared-components/SmfCharts/Bar';
export { default as SmfLineBar } from './shared-components/SmfCharts/LineBar';
export { default as SmfPareto } from './shared-components/SmfCharts/Pareto';
export { default as SmfCustomLegend } from './shared-components/SmfCharts/CustomChartLegend';
export { default as SmfProcessFlow } from './shared-components/SmfCharts/ProcessFlow';
export { default as SmfErrorBoundary } from './shared-components/SmfErrorBoundary';
export { default as SmfErrorPage } from './shared-components/SmfPage/SmfErrorPage';
export { default as PlantTime } from './shared-components/PlantTime';
export { default as SmfSpcHorizontalBar } from './shared-components/SmfCharts/HorizontalBar';
export { default as SmfYPHorizontalBar } from './shared-components/SmfCharts/HorizontalBar';
export { getScaledValue } from './shared-components/SmfCharts/Helper/helper';
export { default as SmfSpcDynamicControlChart } from './shared-components/SmfCharts/DynamicControl';
export { default as SmfYpDynamicControlChart } from './shared-components/SmfCharts/DynamicControl';
export { default as UploadImage } from './shared-components/UploadImage';
export { default as SmfLoadingScreenDialog } from './shared-components/SmfLoadingScreenDialog';
export { default as SmfSelect } from './shared-components/SmfSelect';
export { default as SmfTile } from './shared-components/SmfTiles';
export { default as SmfSearchList } from './shared-components/SmfSearchList';
export { default as SmfSharedTooltip } from './shared-components/SmfSharedTooltip/SmfSharedTooltip';
export { default as SmfBreadcrumbs } from './shared-components/SmfBreadcrumbs';

export const { bootstrap, mount, unmount } = lifecycles;
