import React from 'react';
import PropTypes from 'prop-types';
import SubHeadingArray from './subHeadingArray';
import EllipsizedText from './ellipsizedText';
import SmfStatusDisplay from '../SmfStatusDisplay';

export default function WidgetSubHeader({
  subheader,
  subHeadingTooltip,
  statusChipData = {},
}) {
  const statusDisplayRef = React.createRef(null);
  const [chipWidth, setChipWidth] = React.useState(0);

  React.useEffect(() => {
    if (statusDisplayRef.current)
      setChipWidth(statusDisplayRef.current.offsetWidth);
  }, [statusDisplayRef]);

  if (subHeadingTooltip?.length > 0)
    return (
      <SubHeadingArray
        text={subheader}
        textArray={subHeadingTooltip}
        className="smf-widget-card-subheader-array"
      />
    );

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', width: '100%' }}
      data-testid="smf-widget-subheader-container"
    >
      <div
        style={{
          display: 'block',
          marginRight: '.5rem',
          /**
           *? for better visuals here adding 8.1 because the Status Chip component's paddingX is .5rem
           */
          maxWidth: `calc(100% - ${
            chipWidth + (Object.keys(statusChipData).length ? 8.1 : 0)
          }px)`,
        }}
      >
        <EllipsizedText
          text={subheader}
          className="smf-widget-card-subheader"
        />
      </div>
      <div ref={statusDisplayRef}>
        {!!Object.keys(statusChipData).length && (
          <SmfStatusDisplay {...statusChipData} />
        )}
      </div>
    </div>
  );
}

WidgetSubHeader.propTypes = {
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subHeadingTooltip: PropTypes.arrayOf(PropTypes.string),
  statusChipData: PropTypes.instanceOf(Object),
};
