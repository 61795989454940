import React from 'react';
import Zoom from '@mui/material/Zoom';
import PropTypes from 'prop-types';
import SmfTooltip from '../../SmfTooltip';
import { Tooltip1DBox, Tooltip1DLabel, Tooltip1DValues } from '../styledPeers';

export default function Tooltip1D({
  children,
  isShowTooltip,
  label,
  actualValue,
  targetValue,
}) {
  return (
    <SmfTooltip
      {...(!isShowTooltip && {
        disableFocusListener: true,
        disableHoverListener: true,
      })}
      arrow
      TransitionComponent={Zoom}
      title={
        <Tooltip1DBox>
          <Tooltip1DLabel>{label}</Tooltip1DLabel>
          <Tooltip1DValues>
            Actual: <span>{actualValue}</span>
          </Tooltip1DValues>
          <Tooltip1DValues>
            Target: <span>{targetValue}</span>
          </Tooltip1DValues>
        </Tooltip1DBox>
      }
    >
      {children}
    </SmfTooltip>
  );
}

Tooltip1D.propTypes = {
  children: PropTypes.element,
  isShowTooltip: PropTypes.bool,
  label: PropTypes.string,
  actualValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  targetValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
