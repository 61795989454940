import { Box, Card, styled, TextField, Typography } from '@mui/material';

const getListItemColor = (theme, isSelected) =>
  isSelected
    ? theme.palette.primary.states.outlinedRestingBorder
    : theme.palette.background.default;

export const ItemBox = styled(Box)(({ theme }) => ({
  padding: '1rem',
  borderBottom: `1px solid ${theme.palette.generic.borderOutlined}`,
}));

export const ItemTypography = styled(Typography)(({ theme }) => ({
  marginRight: 'auto',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: theme.palette.text.primary,
}));

export const StyledListItem = styled(Box, {
  shouldForwardProp: (p) => p !== 'isSelected',
})(({ theme, isSelected }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '1rem',
  borderBottom: `1px solid ${theme.palette.generic.borderOutlined}`,
  backgroundColor: getListItemColor(theme, isSelected),
}));

export const StyledSearchField = styled(TextField)(({ theme }) => ({
  borderBottom: `0.063rem solid ${theme.palette.text.primary}`,
}));

export const IconContainer = styled(Box)(({ theme }) => ({
  svg: { path: { fill: theme.palette.text.primary } },
}));

export const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
  paddingBottom: '0.75rem',
}));

export const StyledCard = styled(Card, {
  shouldForwardProp: (p) => p !== 'width',
})(({ theme, width }) => ({
  padding: `0.5rem 0rem 0.5rem 0rem`,
  backgroundColor: theme.palette.background.default,
  borderRadius: '0.5rem',
  width,
  [theme.breakpoints.down('md')]: {
    width: '14rem',
  },
}));
