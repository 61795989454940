import React from 'react';
import { CardContent, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import ExpandIcon from '../../assets/svgs/ExpandIcon.svg';
import CollapseIcon from '../../assets/svgs/CollapseIcon.svg';
import useWindowSize from '../../hooks/useWindowSize';
import { StyledCardActions, CardHeaderStyled, CardStyled } from './styledPeers';
import EllipsizedText from './ellipsizedText';
import WidgetSubHeader from './widgetSubheader';
import { BI_CONSTANTS, TEST_IDS, WIDGET_ICON } from '../../constants';
import { ExploreDataButton, ExploreDataTypography } from '../../utils/styles';

export default function WidgetCard({
  menuAction,
  title,
  subheader,
  hideExpand,
  onExpandClick,
  onCollapseClick,
  expanded,
  subHeadingTooltip,
  statusChipData = {},
  children,
  exploreData,
  cardContentHeight,
  ...others
}) {
  const { width } = useWindowSize();

  return (
    <CardStyled
      data-testid="smf-widget-card"
      className="smf-widget-card"
      {...others}
    >
      <CardHeaderStyled
        action={menuAction}
        title={
          <EllipsizedText text={title} className="smf-widget-card-title" />
        }
        subheader={
          <WidgetSubHeader
            subHeadingTooltip={subHeadingTooltip}
            subheader={subheader}
            statusChipData={statusChipData}
          />
        }
        titleTypographyProps={{ noWrap: true }}
        subheaderTypographyProps={{ noWrap: true }}
      />
      <CardContent
        sx={{
          padding: '0rem .75rem .75rem .75rem',
          margin: 'auto 0',
          ...(cardContentHeight ? { height: cardContentHeight } : {}),
        }}
      >
        {children}
      </CardContent>
      <StyledCardActions disableSpacing>
        {exploreData?.exploreDataUrl && (
          <ExploreDataButton
            data-testid={TEST_IDS.EXPLORE_DATA_BUTTON}
            onClick={() => {
              window.open(exploreData?.exploreDataUrl, '_blank', 'noreferrer');
            }}
          >
            <ExploreDataTypography>
              {BI_CONSTANTS.EXPLORE_DATA_BUTTON_TYPOGRAPHY}
              {exploreData?.exploreDataToolName}
            </ExploreDataTypography>
          </ExploreDataButton>
        )}
        {!expanded && width > 768 && !hideExpand && (
          <IconButton
            data-testid="smf-widget-expand-icon"
            aria-label={WIDGET_ICON.EXPAND}
            onClick={() => onExpandClick()}
            sx={{
              marginLeft: 'auto',
              padding: '0.5rem',
              '.smf-widget-expand-icon': {
                path: { fill: (theme) => theme.palette.action.active },
                height: '1.5rem',
                width: '1.5rem',
              },
            }}
          >
            <ExpandIcon className="smf-widget-expand-icon" />
          </IconButton>
        )}
        {expanded && (
          <IconButton
            data-testid="smf-widget-collapse-icon"
            aria-label={WIDGET_ICON.COLLAPSE}
            onClick={() => onCollapseClick()}
            sx={{
              marginLeft: 'auto',
              padding: '0.5rem',
              '.smf-widget-collapse-icon': {
                path: { fill: (theme) => theme.palette.action.active },
                height: '1.5rem',
                width: '1.5rem',
              },
            }}
          >
            <CollapseIcon className="smf-widget-collapse-icon" />
          </IconButton>
        )}
      </StyledCardActions>
    </CardStyled>
  );
}

WidgetCard.propTypes = {
  menuAction: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hideExpand: PropTypes.bool,
  onExpandClick: PropTypes.func,
  onCollapseClick: PropTypes.func,
  expanded: PropTypes.bool,
  children: PropTypes.node,
  subHeadingTooltip: PropTypes.arrayOf(PropTypes.string),
  statusChipData: PropTypes.instanceOf(Object),
  exploreData: PropTypes.shape({
    exploreDataUrl: PropTypes.string,
    exploreDataToolName: PropTypes.string,
  }),
  cardContentHeight: PropTypes.string,
};
