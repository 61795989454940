import React from 'react';
import { useTheme } from '@mui/material/styles';
import { ResponsiveLine } from '@nivo/line';
import PropTypes from 'prop-types';
import { LineTooltipBox, LineTooltipTypo } from '../styledPeers';
import ThemeWrapper from '../../../utils/ThemeWrapper';
import { formatYAxis, getScaledValue } from '../Helper/helper';
import {
  getPointSymbol,
  getSymbolShape,
  getToolTip,
  lineMarginLeft,
  yValLen,
} from './helperFunctions';
import useWindowSize from '../../../hooks/useWindowSize';

export function LineTooltip({ point, tooltipProps }) {
  const { serieId, data } = point;
  const {
    y,
    xFormatted,
    isReset,
    [serieId]: serieIdData,
    [`${serieId}_tooltipValue`]: tooltipValue,
    isValueNA,
  } = data;
  const { xLabel, symbol = '', position } = tooltipProps;
  const getPointData = () => {
    /**
     * `${serieId}_tooltipValue` this property will hold already formatted tooltip value so returning it directly.
     */
    if (tooltipValue) return tooltipValue;
    /**
     * if isReset is true then it's shift reset value so returning dash
     */
    if (isReset) return '-';
    /**
     * if isValueNA then returning NA
     */
    if (isValueNA) return 'N/A';
    /**
     * else with symbol returning the data
     */
    const pointData = serieIdData ?? y;
    return position === 'prefix'
      ? `${symbol}${pointData}`
      : `${pointData}${symbol}`;
  };

  return (
    <LineTooltipBox data-testid="smf-line-tooltip-box">
      <LineTooltipTypo>
        {xLabel}:{' '}
        <span data-testid="smf-line-tooltip-duration">{xFormatted}</span>
      </LineTooltipTypo>
      <LineTooltipTypo>
        {serieId}:{' '}
        <span data-testid="smf-line-tooltip-value">{getPointData()}</span>
      </LineTooltipTypo>
    </LineTooltipBox>
  );
}

export default function LineChart({
  data,
  xDataFormat,
  xPrecision,
  xTickValues,
  xLabelFormat,
  xLabelRotation = 0,
  yAxisValues = [0, 50, 100, 150],
  yAxisMinValue = 0,
  yAxisMaxValue = 150,
  isDark = false,
  lineColors = ['#00000099', '#BDBDBD'],
  gridLineColor = '#EEEEEE',
  tickTextColor = '#757575',
  legendTextColor = '#000000DE',
  axisBottomTextColor = '#000000DE',
  isYAxisValuesDynamic = false,
  axisBottomLegend = '',
  axisLeftLegend = '',
  axisLeftLegendOffset = -40,
  axisBottomLegendOffset = 40,
  unitOfMeasurement = 'units',
  tooltipProps = { xLabel: 'Duration', symbol: '%', position: 'suffix' },
  ...lineProps // Use this to pass additional props to nivo line component
}) {
  const theme = useTheme();

  const isEmptyData =
    !Array.isArray(data) || !data.length || !Array.isArray(data[0]?.data);

  const priorityId = !isEmptyData ? data[0]?.id : '';
  const priorityDataKeys = React.useMemo(() => new Set(), []);
  const [marginLeftVal, setMarginLeftVal] = React.useState(35);

  const { width: screenWidth } = useWindowSize();
  const getScaledValueWrapper = (value) => getScaledValue(screenWidth, value);

  React.useEffect(() => {
    if (!isEmptyData) {
      let yValMaxLen = Number.MIN_SAFE_INTEGER;
      data?.forEach((datum, datumIdx) => {
        datum?.data?.forEach((point, pointIdx) => {
          if (!datumIdx) priorityDataKeys.add(`${point.timestamp}|${point.y}`);
          yValMaxLen = Math.max(yValMaxLen, yValLen(point.y));

          if (
            datumIdx === data.length - 1 &&
            pointIdx === datum.data.length - 1
          ) {
            setMarginLeftVal(lineMarginLeft(yValMaxLen));
          }
        });
      });
    }
  }, [data, isEmptyData, priorityDataKeys]);

  if (isEmptyData) return null;

  return (
    <ThemeWrapper isDark={isDark}>
      <div
        style={{ height: 'inherit', width: 'inherit' }}
        data-testid="smf-line-chart"
      >
        <ResponsiveLine
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: '.625rem',
                  fill: isDark ? theme.palette.text.secondary : tickTextColor,
                },
              },
              legend: {
                text: {
                  fontSize: '.625rem',
                  fill: axisBottomTextColor,
                },
              },
            },
            legends: {
              text: {
                fontSize: '.625rem',
              },
            },
            grid: {
              line: {
                stroke: isDark
                  ? theme.palette.background.default
                  : gridLineColor,
                strokeWidth: getScaledValueWrapper(1),
              },
            },
          }}
          data={data}
          colors={lineColors}
          margin={{
            top: getScaledValueWrapper(5),
            right: getScaledValueWrapper(35),
            bottom: getScaledValueWrapper(60),
            left: getScaledValueWrapper(marginLeftVal),
          }}
          xScale={{
            type: 'time',
            format: xDataFormat,
            useUTC: false,
            precision: xPrecision,
          }}
          xFormat={`time:${xLabelFormat}`}
          yScale={{
            type: 'linear',
            min: yAxisMinValue,
            max: isYAxisValuesDynamic ? 'auto' : yAxisMaxValue,
            stacked: false,
            reverse: false,
          }}
          pointSymbol={(param) =>
            getPointSymbol(param, isDark, getScaledValueWrapper)
          }
          curve="monotoneX"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 0,
            tickPadding: getScaledValueWrapper(8),
            tickRotation: xLabelRotation,
            format: xLabelFormat,
            tickValues: xTickValues,
            legend: axisBottomLegend,
            legendOffset: axisBottomLegendOffset,
            legendPosition: 'middle',
          }}
          gridYValues={!isYAxisValuesDynamic ? yAxisValues : undefined}
          axisLeft={{
            orient: 'left',
            tickSize: 0,
            tickPadding: getScaledValueWrapper(5),
            tickRotation: 0,
            tickValues: !isYAxisValuesDynamic ? yAxisValues : undefined,
            legend: axisLeftLegend,
            legendOffset: axisLeftLegendOffset,
            legendPosition: 'middle',
            format: (v) => formatYAxis(v, unitOfMeasurement),
          }}
          lineWidth={getScaledValueWrapper(3)}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={getScaledValueWrapper(-24)}
          enableCrosshair={false}
          legends={[
            {
              itemTextColor: legendTextColor,
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: getScaledValueWrapper(12),
              translateY: getScaledValueWrapper(63),
              itemsSpacing: getScaledValueWrapper(0),
              itemDirection: 'left-to-right',
              itemWidth: getScaledValueWrapper(80),
              itemHeight: getScaledValueWrapper(20),
              itemOpacity: getScaledValueWrapper(0.75),
              symbolSpacing: getScaledValueWrapper(16),
              symbolShape: getSymbolShape(getScaledValueWrapper),
            },
          ]}
          motionConfig="default"
          useMesh
          tooltip={(p) =>
            getToolTip(
              p,
              priorityId,
              priorityDataKeys,
              LineTooltip,
              tooltipProps
            )
          }
          {...lineProps}
        />
      </div>
    </ThemeWrapper>
  );
}

LineTooltip.propTypes = {
  point: PropTypes.instanceOf(Object),
  tooltipProps: PropTypes.instanceOf(Object),
};

LineChart.propTypes = {
  data: PropTypes.instanceOf(Array),
  xDataFormat: PropTypes.string,
  xPrecision: PropTypes.string,
  xTickValues: PropTypes.string,
  xLabelFormat: PropTypes.string,
  xLabelRotation: PropTypes.number,
  yAxisValues: PropTypes.instanceOf(Array),
  yAxisMinValue: PropTypes.number,
  yAxisMaxValue: PropTypes.number,
  isDark: PropTypes.bool,
  lineColors: PropTypes.instanceOf(Array),
  gridLineColor: PropTypes.string,
  tickTextColor: PropTypes.string,
  legendTextColor: PropTypes.string,
  axisBottomTextColor: PropTypes.string,
  isYAxisValuesDynamic: PropTypes.bool,
  tooltipProps: PropTypes.instanceOf(Object),
  axisBottomLegend: PropTypes.string,
  axisLeftLegend: PropTypes.string,
  axisLeftLegendOffset: PropTypes.number,
  axisBottomLegendOffset: PropTypes.number,
  unitOfMeasurement: PropTypes.string,
};
