// eslint-disable-next-line no-unused-vars
import React from 'react';
import { StyledSpan } from '../styledPeers';
/**
 * @param {boolean} isExpanded
 * @param {number} width
 * @returns {number}
 */
const percentFromWidth = (isExpanded, width) => {
  if (isExpanded) return 1;
  if (width >= 1400) return 0.85;
  if (width >= 1200) return 0.9;
  if (width >= 900) return 0.95;
  return 0.98;
};
/**
 * @param {boolean} isExpanded
 * @param {number} containerWidth
 * @returns {number}
 */
function getGridFrameWidth(isExpanded, containerWidth) {
  if (isExpanded) return 95;
  if (containerWidth >= 1400) return 55;
  if (containerWidth >= 1200) return 60;
  if (containerWidth >= 1000) return 100;
  if (containerWidth >= 800) return 75;
  if (containerWidth >= 600) return 85;
  return 100;
}
/**
 * @param {boolean} isExpanded
 * @param {number} screenWidth
 * @param {object} containerRef
 * @param {object} bigGaugeGridRef
 * @param {object} smallGaugeGridRef
 * @param {boolean} small
 * @returns {object}
 */
function getStyleProps(
  isExpanded,
  screenWidth,
  containerRef,
  bigGaugeGridRef,
  smallGaugeGridRef,
  singleGaugeRef,
  stylingProps,
  gaugeType = 'small'
) {
  if (screenWidth) {
    const percent = percentFromWidth(isExpanded, screenWidth);

    let bigWidth = Math.round(
      bigGaugeGridRef.current?.offsetWidth
        ? bigGaugeGridRef.current.offsetWidth * percent
        : 0
    );
    const singleWidth = Math.round(
      singleGaugeRef?.current?.offsetWidth
        ? singleGaugeRef.current.offsetWidth +
            (singleGaugeRef.current.offsetWidth * percent) / 2.6
        : 0
    );
    let smallWidth = Math.round(
      smallGaugeGridRef.current?.offsetWidth ?? 0 * percent
    );

    if (bigWidth > containerRef.current.offsetHeight) {
      bigWidth = containerRef.current.offsetHeight;
      smallWidth = containerRef.current.offsetHeight * 0.6;
    }

    const targetFontSize = Math.ceil(
      (!smallWidth ? bigWidth * 0.65 : smallWidth) / 5.6
    );

    const fontSize = singleWidth / 5.6;
    const smallHeight = (bigWidth + smallWidth) / 2;
    let singleGaugeTitleMarginTop = (bigWidth + smallWidth) / 1.3;
    if (smallWidth) {
      singleGaugeTitleMarginTop = bigWidth / 1.4;
    }

    const bigGaugeMarginTop = smallWidth
      ? smallHeight
      : Math.ceil((bigWidth + bigWidth * 0.65) / 2);

    const singleGaugeMarginTop = Math.ceil(
      (singleWidth + singleWidth * 0.65) / 2
    );

    if (gaugeType === 'small')
      return {
        height: smallHeight,
        width: smallWidth,
        targetFontSize,
        targetMarginTop: smallWidth,
      };
    if (gaugeType === 'single')
      return {
        height: singleWidth,
        width: singleWidth,
        fontSize,
        targetMarginTop: singleGaugeMarginTop,
        singleGaugeTitleMarginTop: singleWidth,
        ...stylingProps,
      };

    return {
      height: bigWidth,
      width: bigWidth,
      targetFontSize,
      targetMarginTop: bigGaugeMarginTop,
      singleGaugeTitleMarginTop,
    };
  }
  return null;
}

function isValidMetricName(metricName) {
  if (typeof metricName === 'string') return metricName.length >= 0;
  return false;
}

const renderChildren = (children) => {
  return children.map((child, index) => {
    let key = index;
    let jsxElement;
    if (typeof child === 'string') {
      key = `string_${index}`;
      jsxElement = <StyledSpan key={key}>{child}</StyledSpan>;
    } else {
      key = `${child.props.children || ''}_${index}`;
      jsxElement = (
        <StyledSpan key={key} isActual={child.props.children === 'Actual'}>
          {child.props.children}
        </StyledSpan>
      );
    }
    return jsxElement;
  });
};

export {
  percentFromWidth,
  getGridFrameWidth,
  getStyleProps,
  isValidMetricName,
  renderChildren,
};
