/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import SmfTooltip from '../SmfTooltip';

export default function SubHeadingArray({ textArray, text, className }) {
  const textValue = text.split('|');
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <span>
      <span>{`${textValue[0]} (`}</span>
      <SmfTooltip
        title={
          <div style={{ whiteSpace: 'pre-line' }}>{textArray.join('\r\n')}</div>
        }
        placement="bottom-start"
        disableHoverListener={false}
        data-testid="smf-widget-card-tooltip-array"
        onClose={handleTooltipClose}
        open={open}
      >
        <span
          data-testid="smf-widget-card-text-array"
          className={className}
          onMouseDown={handleTooltipOpen}
          onMouseOver={() => handleTooltipOpen()}
          onMouseLeave={() => handleTooltipClose()}
          style={{ color: '#007CB0', cursor: 'pointer' }}
          role="presentation"
        >
          {textArray.length}
        </span>
      </SmfTooltip>
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          cursor: 'pointer',
        }}
      >{`) | ${textValue[1]}`}</span>
    </span>
  );
}

SubHeadingArray.propTypes = {
  textArray: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};
