import React from 'react';

import {
  styled,
  Tooltip,
  tooltipClasses,
  Switch,
  Typography,
  Button,
  IconButton,
  Box,
} from '@mui/material';

export const typographyStyles = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontSize: '0.75rem',
  fontWeight: '400',
  lineHeight: '1rem',
};

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.grey.grey600,
    padding: '0.25rem 0.5rem',
    alignItems: 'center',
    borderRadius: '0.25rem',
    maxWidth: '18.75rem',
    '&.MuiTooltip-tooltipPlacementBottom': {
      marginTop: '0 !important',
    },
  },
}));

export const StyledSwitch = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'isSwitched',
})(({ theme, isSwitched }) => {
  return {
    '& .MuiSwitch-thumb': {
      background: isSwitched
        ? theme.palette.primary.main
        : theme.palette.grey.grey600,
    },
    '& .MuiSwitch-track': {
      background: isSwitched
        ? theme.palette.primary.main
        : theme.palette.text.primary,
    },
    [theme.breakpoints.only('xl')]: {
      transform: 'scale(2.5)',
      margin: '0 0.7rem 0 1.1rem',
    },
  };
});

export const ToggleTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'textColor',
})(({ theme, textColor = 'primary' }) => {
  let color = theme.palette.text.primary;
  if (textColor === 'secondary') {
    color = theme.palette.text.secondary;
  }
  return {
    ...typographyStyles,
    fontSize: '0.875rem',
    lineHeight: '1.251rem',
    marginLeft: '0.25rem',
    color,
  };
});

export const ExploreDataButton = styled(Button)({
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const ExploreDataTypography = styled(Typography)(({ theme }) => ({
  ...typographyStyles,
  fontWeight: 600,
  fontSize: '0.813rem',
  color: theme.palette.primary.main,
  lineHeight: '1.375rem',
  letterSpacing: '0.029rem',
  textTransform: 'none',
}));

export function BoldTypography(props) {
  return (
    <span {...props} style={{ fontWeight: 'bold', fontSize: '0.625rem' }} />
  );
}
export const StyledSpcDownloadIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})(({ theme, isLoading }) => ({
  padding: '0.5rem',
  marginBottom: '1.1rem',
  backgroundColor: 'transparent',
  '.download-button': {
    path: {
      fill: isLoading
        ? theme.palette.text.disabled
        : theme.palette.primary.main,
    },
  },
}));

export const StyledSpcChartTitle = styled(Typography)(() => ({
  marginBottom: '1rem',
  fontWeight: 400,
  paddingLeft: '0.313rem',
}));

export const StyledSpcChartBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '1.25rem',
  alignItems: 'center',
}));
