import {
  Box,
  styled,
  Typography,
  Backdrop,
  Button,
  IconButton,
} from '@mui/material';

export const StyledUploadImageBox = styled(Box, {
  shouldForwardProp: (props) =>
    props !== 'isLoading' && props !== 'isDisabled' && props !== 'boxStyle',
})(({ theme, isLoading, isDisabled, boxStyle }) => ({
  marginTop: '1rem',
  height: '12.5rem',
  borderRadius: '0.5rem',
  width: '49.375rem',
  borderStyle: 'dashed',
  borderWidth: '0.13rem',
  borderColor: isDisabled
    ? theme.palette.action.disabled
    : theme.palette.primary.main,
  gap: '1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    cursor: !isLoading && !isDisabled ? 'pointer' : 'inherit',
  },
  ...boxStyle,
}));

export const StyledUploadImageText = styled(Typography, {
  shouldForwardProp: (props) => props !== 'isDisabled',
})(({ theme, isDisabled }) => ({
  padding: 0,
  display: 'flex',
  color: isDisabled
    ? theme.palette.action.disabled
    : theme.palette.text.primary,
  fontSize: '1rem',
}));

export const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
}));

export const CustomBox = styled(Box)(({ theme }) => ({
  color: theme.palette.background.default,
  textAlign: 'center',
}));
export const StyledReUploadButton = styled(Button)({
  height: '1.875rem',
  width: '5.563rem',
  textTransform: 'none',
  padding: 0,
});
export const StyledUploadIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isDisabled',
})(({ theme, isDisabled }) => ({
  cursor: isDisabled && 'inherit',
  ':hover': {
    background: isDisabled && 'none',
  },
  '.upload-button': {
    path: {
      fill: isDisabled
        ? theme.palette.text.disabled
        : theme.palette.primary.main,
    },
  },
}));

export const SvgContainer = styled(IconButton)(({ theme }) => ({
  padding: '0.5rem',
  svg: { path: { fill: theme.palette.text.primary } },
}));

export const FileContainer = styled(Box)(() => ({
  borderRadius: '0.5rem',
  width: '17rem',
}));

export const OuterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey.grey200,
  borderRadius: '0.5rem',
  paddingTop: '1.18625rem',
  paddingLeft: '0.75rem',
  width: '17rem',
  height: '5rem',
}));

export const FilenameTypography = styled(Typography)(() => ({
  width: '10.5625rem',
  padding: '0.4rem',
  paddingLeft: '0.75rem',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));
