import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import DefaultIcon from './svg/addButton.svg';
import SearchField from './SearchField';
import ListItem from './ListItem';
import { HeaderBox, ItemBox, ItemTypography, StyledCard } from './style';
import ThemeWrapper from '../../utils/ThemeWrapper';

function SmfSearchList({
  isDark = false,
  headerText = 'List',
  searchList = [],
  hideListIcons = false,
  mapping = { id: 'id', text: 'text' },
  onAddButtonClick = () => {},
  onItemClick = () => {},
  resetSelection = false,
  listHeight = '10.312rem',
  width = '10.312rem',
}) {
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    setData(searchList);
  }, [searchList]);

  useEffect(() => {
    if (!searchText) {
      setData(searchList);
    } else {
      setData(
        searchList.filter((item) =>
          item[mapping.text].toLowerCase().includes(searchText?.toLowerCase())
        )
      );
    }
  }, [searchText, mapping, searchList]);

  useEffect(() => {
    onItemClick(selectedId);
  }, [selectedId]);

  useEffect(() => {
    if (resetSelection) setSelectedId(null);
  }, [resetSelection]);

  return (
    <ThemeWrapper isDark={isDark}>
      <StyledCard width={width} data-testId="smf-search-list-component">
        <HeaderBox>
          <ItemTypography>{headerText}</ItemTypography>
          <IconButton
            onClick={onAddButtonClick}
            data-testId="smf-search-list-add-button"
          >
            <DefaultIcon
              width="1.5rem"
              height="1.5rem"
              data-testid="SmfSearchList-add-button"
            />
          </IconButton>
        </HeaderBox>
        <ItemBox>
          <SearchField
            sx={{ width: '100%' }}
            value={searchText}
            onChange={(value) => setSearchText(String(value))}
          />
        </ItemBox>
        <Box sx={{ overflowY: 'scroll', height: listHeight }}>
          {data.map((item) => (
            <ListItem
              key={item[mapping.id]}
              text={item[mapping.text]}
              data-testid={`item-${item[mapping.id]}`}
              onClick={() => setSelectedId(item[mapping.id])}
              hideIcon={!!hideListIcons}
              isSelected={item[mapping.id] === selectedId}
            />
          ))}
        </Box>
      </StyledCard>
    </ThemeWrapper>
  );
}

SmfSearchList.propTypes = {
  isDark: PropTypes.bool,
  headerText: PropTypes.string,
  searchList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    })
  ),
  hideListIcons: PropTypes.bool,
  onItemClick: PropTypes.func,
  onAddButtonClick: PropTypes.func,
  resetSelection: PropTypes.bool,
  mapping: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  }),
  width: PropTypes.string,
  listHeight: PropTypes.string,
};

export default SmfSearchList;
