const lightTheme = require('../../theme/theme-light');
const darkTheme = require('../../theme/theme-dark');

const xAxisLabel = (isDark) => ({
  labelColor: isDark
    ? darkTheme.darkPalette.text.secondary
    : lightTheme.lightPalette.text.secondary,
});

const tooltipCustom = (isDark) => ({
  backgroundColorForTooltip: isDark
    ? darkTheme.darkPalette.grey.grey600
    : lightTheme.lightPalette.grey.grey600,
  fontColor: isDark
    ? darkTheme.darkPalette.primary.contrast
    : lightTheme.lightPalette.primary.contrast,
});

const barColor = {
  currentBar: '#009A44',
  futureBar: darkTheme.darkPalette.charts.secondary2,
};

const chartLinesColor = (isDark) => ({
  lslLineColor: isDark
    ? darkTheme.darkPalette.grey.grey400
    : lightTheme.lightPalette.grey.grey400,
  uslLineColor: isDark
    ? darkTheme.darkPalette.grey.grey400
    : lightTheme.lightPalette.grey.grey400,
  nominalLineColor: isDark
    ? darkTheme.darkPalette.grey.grey400
    : lightTheme.lightPalette.grey.grey400,
  middleLineColor: isDark
    ? darkTheme.darkPalette.grey.grey200
    : lightTheme.lightPalette.grey.grey200,
  currentMeanLineColor: '#009A44',
  futureMeanLineColor: darkTheme.darkPalette.charts.secondary2,
});

const horizontalBoxChartConstant = {
  currentMean: 'Current Mean',
  futureMean: 'Future Mean',
  meanPlusStdev: 'Mean + STDEV',
  meanMinusStdev: 'Mean - STDEV',
  stdev: 'STDEV',
  plusMinusStdev: '+/-STDEV',
  lsl: 'LSL',
  usl: 'USL',
  nominal: 'Nominal',
};

const emptyGraphDataFunction = (tempData) => {
  return {
    labels: ['', '', '', '', ''],
    datasets: [
      {
        data: tempData,
      },
    ],
  };
};

function getTooltipData(tooltipItem, data) {
  const toolTitle = data[tooltipItem[0].dataIndex];
  return `${toolTitle.toolTipLabel}: ${toolTitle?.mean?.toFixed(
    3
  )}\nSTDEV: ${toolTitle?.stdev?.toFixed(
    3
  )}\nMean + STDEV: ${tooltipItem[0]?.raw[1]?.toFixed(
    3
  )}\nMean - STDEV: ${tooltipItem[0]?.raw[0]?.toFixed(3)}`;
}

const tooltipCallback = (value, lsl, usl, mean) => {
  if (value === lsl) {
    return horizontalBoxChartConstant.lsl;
  }
  if (value === usl) {
    return horizontalBoxChartConstant.usl;
  }
  if (value === mean) {
    return horizontalBoxChartConstant.nominal;
  }
  return value;
};

const afterBuildTicks = (scale, lsl, usl, mean) => {
  // eslint-disable-next-line no-param-reassign
  scale.ticks = [
    {
      value: lsl,
    },
    {
      value: mean,
    },
    {
      value: usl,
    },
  ];
};

module.exports = {
  xAxisLabel,
  tooltipCustom,
  barColor,
  horizontalBoxChartConstant,
  chartLinesColor,
  getTooltipData,
  tooltipCallback,
  afterBuildTicks,
  emptyGraphDataFunction,
};
