import { Box, styled, Typography } from '@mui/material';

const StyledLabels = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontFamily: 'Open Sans',
  fontSize: '0.6875rem',
  fontStyle: 'normal',
  fontWeight: 400,
}));

const StyledLabelsBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.20894rem',
}));

export { StyledLabels, StyledLabelsBox };
