import React from 'react';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { MESSAGE_STRINGS } from '../../../constants';
import Arrow from '../../../assets/svgs/ArrowUpward.svg';

export default function HeaderSortArrow({
  id,
  selectedSort,
  sortHandler,
  nextSortingOrder,
  hideSortIcon,
  sortIconClicked,
  ...rest
}) {
  if (hideSortIcon) return null;

  return (
    <IconButton
      className={`changeVisibility${id}`}
      aria-label={MESSAGE_STRINGS.SORT_BUTTON}
      onClick={(e) => {
        sortHandler(e);
        sortIconClicked({
          columnName: id,
          sortOrder: nextSortingOrder,
        });
      }}
      sx={{
        visibility: selectedSort !== false ? true : 'hidden',
        transition: 'all 0.25s ease',
        transform: `rotate(${
          selectedSort === 'desc' ||
          (selectedSort === false && nextSortingOrder === 'desc')
            ? '0.5turn'
            : 0
        })`,
        '.sortArrowIcon': {
          path: {
            fill: (theme) => theme.palette.action.active,
          },
        },
      }}
      {...rest}
    >
      <Arrow
        height="1.25rem"
        width="1.25rem"
        className={`changeOpacity${id} sortArrowIcon`}
      />
    </IconButton>
  );
}

HeaderSortArrow.propTypes = {
  id: PropTypes.string,
  selectedSort: PropTypes.oneOf(['asc', 'desc', false]),
  sortHandler: PropTypes.func,
  nextSortingOrder: PropTypes.oneOf(['asc', 'desc', false]),
  hideSortIcon: PropTypes.bool,
  sortIconClicked: PropTypes.func,
};
