import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import WidgetCard from './widgetCard';
import {
  LastUpdatedTypo,
  WidgetChildrenWrapper,
  WidgetWrapper,
} from './styledPeers';
import ThemeWrapper from '../../utils/ThemeWrapper';

const ExpandContext = React.createContext();

function Widget(props) {
  const {
    menuAction,
    title,
    subheader,
    lastUpdatedAt,
    hideExpand = false,
    isExpanded,
    onExpandClick = () => {},
    onCollapseClick = () => {},
    expandedCardHeight,
    children,
    subHeadingTooltip,
    widgetType,
    cardContentHeight,
    ...others
  } = props;
  const isExpandedMemo = React.useMemo(() => ({ isExpanded }), [isExpanded]);
  return (
    <WidgetCard
      {...(!isExpanded && { menuAction })}
      title={title}
      subheader={subheader}
      subHeadingTooltip={subHeadingTooltip}
      expanded={isExpanded}
      hideExpand={hideExpand}
      onExpandClick={onExpandClick}
      onCollapseClick={onCollapseClick}
      cardContentHeight={cardContentHeight}
      {...others}
    >
      <ExpandContext.Provider value={isExpandedMemo}>
        <WidgetChildrenWrapper
          isExpanded={isExpanded}
          expandedCardHeight={widgetType === 'PPE_INSIGHTS' ? '64vh' : '50vh'}
          widgetType={widgetType}
          data-testid="smf-widget-children-container"
          className="smf-widget-children-container"
        >
          {children}
          {lastUpdatedAt && (
            <LastUpdatedTypo tabIndex={0}>{lastUpdatedAt}</LastUpdatedTypo>
          )}
        </WidgetChildrenWrapper>
      </ExpandContext.Provider>
    </WidgetCard>
  );
}

export default function SmfWidget(props) {
  const { isDark = false, onExpandChange = () => {}, title } = props;
  const { expandedTitle = title } = props;

  const [isWidgetExpanded, setIsWidgetExpanded] = React.useState(false);

  return (
    <ThemeWrapper isDark={isDark}>
      <WidgetWrapper data-testid="smf-widget-container">
        <Widget
          {...props}
          onExpandClick={() => {
            setIsWidgetExpanded(true);
            onExpandChange(true);
          }}
        />
        {isWidgetExpanded && (
          <Dialog
            fullWidth
            open={isWidgetExpanded}
            maxWidth={false}
            data-testid="smf-widget-expanded-container"
            onClose={() => {
              setIsWidgetExpanded(false);
              onExpandChange(false);
            }}
            slotProps={{
              backdrop: {
                sx: {
                  backgroundColor: (theme) =>
                    theme.palette.generic.backdrop.overlay,
                },
              },
            }}
          >
            <DialogContent
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                padding: 0,
              }}
            >
              <Widget
                {...props}
                title={expandedTitle}
                onCollapseClick={() => {
                  setIsWidgetExpanded(false);
                  onExpandChange(false);
                }}
                isExpanded
              />
            </DialogContent>
          </Dialog>
        )}
      </WidgetWrapper>
    </ThemeWrapper>
  );
}

export const useWidgetIsExpanded = () => React.useContext(ExpandContext);

Widget.propTypes = {
  menuAction: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  lastUpdatedAt: PropTypes.string,
  isDark: PropTypes.bool,
  hideExpand: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onExpandClick: PropTypes.func,
  onCollapseClick: PropTypes.func,
  expandedCardHeight: PropTypes.string,
  children: PropTypes.node,
  widgetType: PropTypes.string,
  subHeadingTooltip: PropTypes.arrayOf(PropTypes.string),
  cardContentHeight: PropTypes.string,
};

SmfWidget.propTypes = {
  menuAction: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  lastUpdatedAt: PropTypes.string,
  isDark: PropTypes.bool,
  hideExpand: PropTypes.bool,
  children: PropTypes.node,
  onExpandChange: PropTypes.func,
  widgetType: PropTypes.string,
  expandedTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cardContentHeight: PropTypes.string,
};
