import {
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';

export const getInitialConfig = (obj) => {
  const configObj = {
    sorting: true,
    search: true,
    pagination: false,
    footer: false,
    expanded: false,
    ...obj,
  };
  let initialConfig = {};
  if (configObj.sorting) {
    initialConfig = {
      ...initialConfig,
      getSortedRowModel: getSortedRowModel(),
    };
  }
  if (configObj.pagination) {
    initialConfig = {
      ...initialConfig,
      autoResetPageIndex: false,
      getPaginationRowModel: getPaginationRowModel(),
    };
  }
  if (configObj.search) {
    initialConfig = {
      ...initialConfig,
      getFilteredRowModel: getFilteredRowModel(),
    };
  }
  if (configObj.expanded) {
    initialConfig = {
      ...initialConfig,
      filterFromLeafRows: true,
      getExpandedRowModel: getExpandedRowModel(),
    };
  }
  return { initialConfig, configObj };
};

export const globalFilterFn = (row, columnId, filterValue) => {
  const search = filterValue?.toLowerCase();

  let value = row.getValue(columnId);
  if (typeof value === 'number') value = String(value);

  return value?.toLowerCase().includes(search);
};
