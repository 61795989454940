/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box, Slider, SliderMark, Typography } from '@mui/material';
import ThemeWrapper from '../../utils/ThemeWrapper';
import { StyledTooltip } from '../../utils/styles';

function CustomMarkComponent(props) {
  const { marks, isTooltipEnabled, 'data-index': dataIndex, ...rest } = props;
  const mark = marks[dataIndex];
  return (
    <StyledTooltip
      title={
        isTooltipEnabled
          ? marks.map((marker) => (
              <p
                style={{
                  fontSize: '.625rem',
                  lineHeight: '.875rem',
                }}
                key={`${marker.displayLabel}=${marker.displayValue}`}
              >
                {marker.displayLabel}:{' '}
                <b style={{ fontSize: '.625rem', lineHeight: '.875rem' }}>
                  {marker.displayValue}
                </b>
              </p>
            ))
          : null
      }
    >
      <SliderMark
        sx={{
          color: (theme) => mark.getColor(theme),
          backgroundColor: (theme) => mark.getColor(theme),
        }}
        data-index={dataIndex}
        {...rest}
      />
    </StyledTooltip>
  );
}
CustomMarkComponent.propTypes = {
  'data-index': PropTypes.number,
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.func,
      value: PropTypes.number,
      displayLabel: PropTypes.string,
      displayValue: PropTypes.number,
    })
  ),
};

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.grey.grey200,
  cursor: 'default',
  '@media (pointer: coarse)': {
    padding: '0',
  },
  '.MuiSlider-mark': {
    borderRadius: '50%',
  },
  '.MuiSlider-thumb': {
    display: 'none',
  },
  '.MuiSlider-thumb:is(:hover, .Mui-active)': {
    display: 'none',
  },
}));

export function SmfCustomBarGraph({
  targetUnits,
  height,
  width,
  target,
  colorMapping = {
    calculatedTarget: {
      getColor: (theme) => theme.palette.secondary.dark,
      label: 'Target',
    },
    calculatedMaxDemonstrated: {
      getColor: (theme) => theme.palette.secondary.light,
      label: 'text',
    },
    calculatedMaxTheoritical: {
      getColor: (theme) => theme.palette.primary.light,
      label: 'text',
    },
  },
  markObj = {},
  isDark,
  getPercentageColor = (theme) => theme.palette.error.main,
  isTooltipEnabled = false,
  offSetMargin = '0.25rem',
  ...rest
}) {
  const marks = Object.keys(markObj).map((key) => ({
    getColor: colorMapping[key]
      ? colorMapping[key].getColor
      : (theme) => theme.palette.secondary.dark,
    value:
      (100 * markObj[key]) / target > 100 ? 100 : (100 * markObj[key]) / target,
    displayLabel: colorMapping[key]?.label,
    displayValue: markObj[key],
  }));
  const percentageOfTarget = (100 * targetUnits) / target;
  const getBackgroundColor = (theme) => {
    if (typeof getPercentageColor === 'string') return getPercentageColor;
    return getPercentageColor
      ? getPercentageColor(theme)
      : theme.palette.error.main;
  };
  return (
    <ThemeWrapper isDark={isDark}>
      <Box
        display="flex"
        alignItems="center"
        className="smf-single-bar-graph"
        sx={{ width }}
      >
        <CustomSlider
          data-testid="smf-single-bar-graph"
          components={{
            Mark: CustomMarkComponent,
          }}
          slotProps={{ mark: { marks, isTooltipEnabled } }}
          size="1.5rem"
          track="inverted"
          aria-labelledby="smf-single-bar-graph"
          marks={marks}
          value={percentageOfTarget}
          valueLabelDisplay="auto"
          focusVisible={false}
          sx={{
            height,
            padding: 0,
            '.MuiSlider-mark': {
              width: height,
              height,
            },
            '.MuiSlider-track': {
              border: 'none',
              backgroundColor: getBackgroundColor,
            },
          }}
          {...rest}
        />
        <Typography
          className="smf-single-bar-caption"
          data-testid="smf-single-bar-caption"
          variant="caption"
          sx={{
            color: (theme) => theme.palette.text.primary,
            marginLeft: offSetMargin,
          }}
        >
          {target}
        </Typography>
      </Box>
    </ThemeWrapper>
  );
}
SmfCustomBarGraph.propTypes = {};

export default SmfCustomBarGraph;
