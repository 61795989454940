import React from 'react';
import { FormControl } from '@mui/material';
import PropTypes from 'prop-types';
import ThemeWrapper from '../../utils/ThemeWrapper';
import { StyledInputLabel, StyledMenuItem, StyledSelect } from './style';

export default function SmfSelect({
  children,
  isDark,
  FormControlProps = {},
  SelectProps = {},
  InputLabelProps = {},
  label = '',
  labelId = '',
}) {
  return (
    <ThemeWrapper isDark={isDark}>
      <FormControl fullWidth {...FormControlProps}>
        <StyledInputLabel id={labelId} {...InputLabelProps}>
          {label}
        </StyledInputLabel>
        <StyledSelect labelId={labelId} label={label} {...SelectProps}>
          {children}
        </StyledSelect>
      </FormControl>
    </ThemeWrapper>
  );
}
SmfSelect.MenuItem = StyledMenuItem;
SmfSelect.propTypes = {
  children: PropTypes.node.isRequired,
  isDark: PropTypes.bool,
  FormControlProps: PropTypes.shape({}),
  SelectProps: PropTypes.shape({}),
  InputLabelProps: PropTypes.shape({}),
  label: PropTypes.string,
  labelId: PropTypes.string,
};
