import React from 'react';
import { PropTypes } from 'prop-types';
import { TextField } from '@mui/material';
import ThemeWrapper from '../../utils/ThemeWrapper';
import { StyledAutocomplete } from './style';
import { SIZE, VARIANT } from '../../constants';

export default function SmfAutocomplete({
  value = null,
  options = [],
  isDark = false,
  disabled = false,
  noOptionsText = '',
  onChange = () => {},
  placeholderText = '',
  variant = VARIANT.OUTLINED,
  size = SIZE.SMALL,
  ...rest
}) {
  return (
    <ThemeWrapper isDark={isDark}>
      <StyledAutocomplete
        data-testid="smf-autocomplete"
        className="smf-autocomplete"
        value={value}
        options={options}
        onChange={onChange}
        disabled={disabled}
        noOptionsText={noOptionsText}
        size={size}
        renderInput={(params) => (
          <TextField
            {...params}
            data-testid="smf-autocomplete-input"
            className="smf-autocomplete-input"
            label={placeholderText}
            variant={variant}
          />
        )}
        {...rest}
      />
    </ThemeWrapper>
  );
}

SmfAutocomplete.propTypes = {
  options: PropTypes.instanceOf(Array),
  noOptionsText: PropTypes.string,
  onChange: PropTypes.func,
  placeholderText: PropTypes.string,
  isDark: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  value: PropTypes.instanceOf(Object),
  variant: PropTypes.string,
};
