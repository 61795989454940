import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import Option from '../../../assets/svgs/MoreVert.svg';
import MESSAGE_STRINGS from '../../../constants/en-us';
import { SORT_ORDER } from '../../../constants';

export default function HeaderOptionsMenu({
  id,
  selectedSort,
  clearSorting,
  toggleSorting,
  sortIconClicked,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id={`table-option-button-${id}`}
        data-testid={`header-option-button-${id}`}
        aria-controls={open ? 'table-header-option-button' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="smf-table-header-option"
        sx={{
          '& .header-option-icon': {
            path: {
              fill: (theme) => theme.palette.action.active,
            },
          },
        }}
      >
        <Option
          height="1.25rem"
          width="1.25rem"
          className="header-option-icon"
        />
      </IconButton>
      <Menu
        id={`table-option-button-${id}`}
        data-testid={`header-option-menu-${id}`}
        aria-labelledby="table-header-option-menu"
        className="smf-table-header-option-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '.MuiMenu-paper': {
            background: (theme) =>
              `${theme.palette.background.paper} !important`,
            color: (theme) => `${theme.palette.text.primary} !important`,
          },
        }}
      >
        <MenuItem
          disabled={selectedSort === false}
          onClick={() => {
            clearSorting();
            handleClose();
            sortIconClicked({ sortOrder: '' });
          }}
          data-testid="menu-option-unsort"
        >
          {MESSAGE_STRINGS['SMFToast.options.sort.unsort']}
        </MenuItem>
        <MenuItem
          disabled={selectedSort === 'asc'}
          onClick={() => {
            toggleSorting(false);
            handleClose();
            sortIconClicked({
              columnName: id,
              sortOrder: SORT_ORDER.ASCENDING,
            });
          }}
          data-testid="menu-option-asc"
        >
          {MESSAGE_STRINGS['SMFToast.options.sort.asc']}
        </MenuItem>
        <MenuItem
          disabled={selectedSort === 'desc'}
          onClick={() => {
            toggleSorting(true);
            handleClose();
            sortIconClicked({
              columnName: id,
              sortOrder: SORT_ORDER.DESCENDING,
            });
          }}
          data-testid="menu-option-desc"
        >
          {MESSAGE_STRINGS['SMFToast.options.sort.desc']}
        </MenuItem>
      </Menu>
    </>
  );
}

HeaderOptionsMenu.propTypes = {
  id: PropTypes.string,
  selectedSort: PropTypes.oneOf(['asc', 'desc', false]),
  clearSorting: PropTypes.func,
  toggleSorting: PropTypes.func,
  sortIconClicked: PropTypes.func,
};
