import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import AlertCheckCircle from '../../assets/svgs/AlertCheckCircle.svg';
import AlertReportProblem from '../../assets/svgs/AlertReportProblem.svg';
import ThemeWrapper from '../../utils/ThemeWrapper';

const CustomAlert = React.forwardRef(function CustomAlert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const alertCSS = {
  color: (theme) => theme.palette.success.contrast,
  '.MuiAlert-icon': {
    alignItems: 'center',
    fontSize: '1.375rem',
    marginRight: '0.75rem',
    padding: '0.4375rem 0',
  },
  '&.MuiAlert-filledSuccess': {
    background: (theme) => theme.palette.success.main,
  },
  '&.MuiAlert-filledWarning': {
    background: (theme) => theme.palette.warning.main,
  },
  '& .MuiAlert-message': {
    padding: '0.5rem 0',
    color: (theme) => theme.palette.success.contrast,
  },
  '& .MuiAlert-action': {
    paddingTop: '0',
    alignItems: 'center',
  },
  '& .smf-alert-icon': {
    path: {
      fill: (theme) => theme.palette.success.contrast,
    },
  },
};

function IconWrapper({ children, iconWrapperCss }) {
  const boxStyle = {
    height: '1.375rem',
    width: '1.375rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return iconWrapperCss ? children : <Box sx={boxStyle}>{children}</Box>;
}

IconWrapper.propTypes = {
  iconWrapperCss: PropTypes.bool,
  children: PropTypes.shape({}),
};
export default function SmfAlert({
  isOpen = false,
  anchorOrigin = { vertical: 'top', horizontal: 'center' },
  type = 'success',
  message = 'This is a success message!. i am from shared',
  isDark = false,
  autoWidth = false,
  additionalCss,
  ...rest
}) {
  const [open, setOpen] = useState(isOpen);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  // IconMapping object utilizing IconWrapper
  const iconMapping = {
    success: (
      <IconWrapper iconWrapperCss={additionalCss}>
        <AlertCheckCircle fontSize="inherit" className="smf-alert-icon" />
      </IconWrapper>
    ),
    warning: (
      <IconWrapper iconWrapperCss={additionalCss}>
        <AlertReportProblem fontSize="inherit" className="smf-alert-icon" />
      </IconWrapper>
    ),
  };

  return (
    <ThemeWrapper isDark={isDark}>
      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        sx={{
          '&	.MuiSnackbarContent-action': {
            alignItems: 'center',
            padding: 0,
          },
        }}
        data-testid={`alert-${type}`}
        {...rest}
      >
        <CustomAlert
          onClose={handleClose}
          severity={type}
          iconMapping={iconMapping}
          sx={{
            ...alertCSS,
            padding: '0.4rem 1rem',
            width: autoWidth ? 'auto' : '26.5rem',
          }}
          data-testid="alert-content"
        >
          <Typography noWrap={autoWidth} variant="body2">
            {message}
          </Typography>
        </CustomAlert>
      </Snackbar>
    </ThemeWrapper>
  );
}

SmfAlert.propTypes = {
  isOpen: PropTypes.bool,
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.string,
    vertical: PropTypes.string,
  }),
  type: PropTypes.string,
  message: PropTypes.string,
  isDark: PropTypes.bool,
  autoWidth: PropTypes.bool,
  additionalCss: PropTypes.bool,
  iconWrapperCss: PropTypes.bool,
  children: PropTypes.shape({}),
};
