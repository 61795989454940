import { styled } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import ThemeWrapper from '../../utils/ThemeWrapper';

const CustomToolTip = styled(
  ({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'maxWidth',
  }
)(({ theme, maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.grey.grey600,
    borderRadius: '.25rem',
    maxWidth,
    alignItems: 'center',
  },
}));

function SmfSharedTooltip({
  children,
  maxWidth = '14.5rem',
  isDark,
  ...props
}) {
  return (
    <ThemeWrapper isDark={isDark}>
      <CustomToolTip maxWidth={maxWidth} {...props}>
        {children}
      </CustomToolTip>
    </ThemeWrapper>
  );
}
SmfSharedTooltip.propTypes = {
  children: PropTypes.node,
  isDark: PropTypes.bool,
  maxWidth: PropTypes.string,
};

export default SmfSharedTooltip;
