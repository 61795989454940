import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';

import { nonceCache } from '@smf/ui-main-container-app';
import lightTheme from '../theme/theme-light';
import darkTheme from '../theme/theme-dark';

function ThemeWrapper({ isDark, children }) {
  return (
    <CacheProvider value={nonceCache}>
      <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
}

ThemeWrapper.propTypes = {
  isDark: PropTypes.bool,
  children: PropTypes.node,
};

export default ThemeWrapper;
