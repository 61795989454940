/* eslint-disable prefer-destructuring */

const darkPalette = {
  labelColor: 'rgba(0, 163, 224, 0.20)',
  barColor: '#6FC2B4',
  labelTextColor: '#A7A8AA',
};

const lightPalette = {
  labelColor: 'rgba(0, 124, 176, 0.08)',
  barColor: '#0097A9',
  labelTextColor: 'rgba(0, 0, 0, 0.60)',
};

const barInteractionPlugin = {
  id: 'barInteractionPlugin',
  beforeEvent: (chart, args, pluginOptions) => {
    const { type, y } = args.event;

    if (type === 'mousemove' || type === 'mouseout') {
      let isCursorOverBar = false;
      chart.data.datasets.forEach((dataset, datasetIndex) => {
        dataset.data.forEach((value, index) => {
          const barElement = chart.getDatasetMeta(datasetIndex).data[index];
          if (barElement.inYRange(y)) {
            isCursorOverBar = true;
          }
        });
      });
      /* eslint-disable no-param-reassign */
      chart.canvas.style.cursor = isCursorOverBar ? 'pointer' : 'default';
    }

    if (type === 'click') {
      chart.data.datasets.forEach((dataset, datasetIndex) => {
        dataset.data.forEach((value, index) => {
          const barElement = chart.getDatasetMeta(datasetIndex).data[index];
          if (barElement.inYRange(y)) {
            pluginOptions?.handleBarSelection(index);
          }
        });
      });

      chart.update();
    }
  },
  beforeDraw: (chart, _, pluginOptions) => {
    let interactLabelColor = lightPalette.labelColor;
    if (pluginOptions.isDark) {
      interactLabelColor = darkPalette.labelColor;
    }
    let interactBarColor = lightPalette.barColor;
    if (pluginOptions.isDark) {
      interactBarColor = darkPalette.barColor;
    }
    const selectedIndex = pluginOptions?.selectedIndex;
    const ctx = chart.ctx;
    const yAxis = chart.scales.y;
    yAxis.ticks.forEach((tick, index) => {
      const barElement = chart.getDatasetMeta(0).data[index];
      if (selectedIndex === index) {
        ctx.fillStyle = interactLabelColor;
        ctx.fillRect(
          yAxis.left - 30,
          yAxis.getPixelForTick(index) - 12,
          chart.width + 30,
          25
        );
        barElement.options.backgroundColor = interactBarColor;
      } else if (
        barElement?.custom &&
        (barElement?.custom.hoverLabelColor ||
          barElement?.custom.clickedLabelColor)
      ) {
        ctx.fillStyle =
          barElement?.custom.clickedLabelColor ||
          barElement?.custom.hoverLabelColor;
        ctx.fillRect(
          yAxis.left - 30,
          yAxis.getPixelForTick(index) - 12,
          chart.width + 30,
          25
        );
        barElement.options.backgroundColor = interactBarColor;
      }
    });
  },
};

const yLabelPlugin = {
  id: 'yLabelPlugin',
  beforeDraw: (chart, _, pluginOptions) => {
    let labelTextColor = lightPalette.labelTextColor;
    if (pluginOptions.isDark) {
      labelTextColor = darkPalette.labelTextColor;
    }
    const ctx = chart.ctx;
    const yAxis = chart.scales.y;
    ctx.font = '0.75rem Open Sans';
    ctx.fillStyle = labelTextColor;

    const maxWidth = 200;
    const ellipsis = '...';
    const labelsWithEllipsis = [];

    yAxis.ticks.forEach((tick, index) => {
      let label = tick.label;
      let textWidth = ctx.measureText(label).width;
      let addEllipses = false;
      while (textWidth > maxWidth && label.length > 0) {
        label = label.substring(0, label.length - 1);
        textWidth = ctx.measureText(label + ellipsis).width;
        addEllipses = true;
      }

      if (addEllipses) {
        label = label.trim();
        label += ellipsis;
        labelsWithEllipsis.push({
          label: tick.label,
          truncatedLabel: label,
          index,
        });
      }

      const y = yAxis.getPixelForTick(index) + yAxis.paddingTop;
      ctx.fillText(label, yAxis.left, y + 4);
    });
  },
};

const getThemeColors = (isDark) => ({
  barBackgroundColor: isDark ? '#004F59' : 'rgba(0, 151, 169, 0.5)',
  tickColor: isDark ? '#A7A8AA' : 'rgba(0, 0, 0, 0.60)',
  scaleTitleColor: isDark ? '#DEDEDE' : 'rgba(0, 0, 0, 0.87)',
  xGridColor: isDark ? '#4E4E4E' : '#C4C4C4',
});

const lowerAndReplaceSpace = (str) => str.toLowerCase().replaceAll(' ', '-');

module.exports = {
  barInteractionPlugin,
  yLabelPlugin,
  getThemeColors,
  lowerAndReplaceSpace,
};
